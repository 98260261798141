enum FieldMaskTypes {
  'number' = 'number',
  'timeInMonths' = 'timeInMonths',
  'currency' = 'currency',
  'phone' = 'phone',
  'fullDate' = 'fullDate',
  'monthYear' = 'monthYear',
  'alphaNumeric' = 'alphaNumeric',
  'ssn' = 'ssn',
}

enum FieldFormatTypes {
  'state_abbreviation' = 'state_abbreviation',
  'lowercase' = 'lowercase',
  'date_substring_concat' = 'date_substring_concat',
  'addressNumber_StreetConcat' = 'addressNumber_StreetConcat',
  'convertToDecimal' = 'convertToDecimal',
  'reformat_date' = 'reformat_date',
  'regex' = 'regex',
  'ssnSubstring' = 'ssnSubstring',
  'substring' = 'substring',
  'multiply' = 'multiply',
  'phone' = 'phone',
  'nextPayDate' = 'nextPayDate',
  'integer' = 'integer',
  'string' = 'string',
  'mobilePhone' = 'mobilePhone',
  'strip' = 'strip',
  'stripAllSpecialCharacters' = 'stripAllSpecialCharacters',
  'removeAllWhitespace' = 'removeAllWhitespace',
  'string_split' = 'string_split',
  'mapObject' = 'mapObject',
  'test' = 'test',
}

interface FieldLenderMasksI {
  [k: number]: FieldMaskTypes;
}

interface FieldLenderRequiredI {
  [k: number]: boolean;
}

interface FieldLenderApiAppI {
  [k: number]: boolean;
}

interface FieldLenderWebAppI {
  [k: number]: boolean;
}

interface FieldOptionsI {
  value: string;
  label: string;
  lender_map_value?: string;
}

interface FieldLenderOptionsI {
  [k: number]: FieldOptionsI;
}

interface FieldLenderConditionsI {
  [k: number]: any;
}

interface FieldLenderFormatI {
  [k: number]: FieldFormatTypes | FieldFormatTypes[];
}

enum ValidationSchemaTypes {
  'string' = 'string',
  'required' = 'required',
  'nullable' = 'nullable',
  'number' = 'number',
  'phone' = 'phone',
  'date:required' = 'date:required',
  'max:50' = 'max:50', // these will be strings
  'min:7' = 'min:7',
  'alphaNumericWithSpacesOnly' = 'alphaNumericWithSpacesOnly',
  'onlyNumbers' = 'onlyNumbers',
  'passWdLetterNumberRestrictSpecialChar' = 'passWdLetterNumberRestrictSpecialChar',
  'oneOf' = 'oneOf',
  'alphaNumericOnly' = 'alphaNumericOnly',
  'isCurrencyGreaterThan' = 'isCurrencyGreaterThan',
}

// these are pre-built components
enum FieldDetailComponentTypes {
  'sensitive' = 'sensitive',
  'email' = 'email',
  'select' = 'select',
  'years' = 'years',
  'text' = 'text',
  'state' = 'state',
  'record' = 'record',
  'date' = 'date',
}

// These are Yup types for validation
export enum FieldDetailTypes {
  'string' = 'string',
  'number' = 'number',
  'date' = 'date',
  'object' = 'object',
  'bool' = 'bool',
  'mixed' = 'mixed',
  'array' = 'array',
}

enum FieldDisclosureTypes {
  'mobile_phone' = 'mobile_phone',
}

interface FieldParams {
  aheadOrBehind?: string;
  maxOffset?: number;
  minOffset?: number;
  spread?: boolean;
  sort?: string;
}
export interface RootFieldDetailsI {
  order: number;
  type: FieldDetailTypes;
  mask: FieldMaskTypes;
  validations: ValidationSchemaTypes[] | string[];
  component?: FieldDetailComponentTypes;
  msg?: string; // message that can be displayed below the field
  disclosure?: FieldDisclosureTypes;
  is_verification?: boolean; // marks whether the field is part of the verification
  label: string;
  faker: any;
  enum?: string;
  name: string;
  least_common_options: any[]; // least common options accross all lenders
  all_options: any[]; // all options available across all lenders
  base_options: any[]; // the standard options that we have now
  condition?: any;
  required?: boolean;
  specialDisclosure?: boolean; // I want to remove this eventually in place of special parser
  params?: FieldParams;
  format?: string;
}

export interface FieldDetailI {
  id: string;
  section: string;
  always_required: boolean;
  lender_details: {
    lender_masks: FieldLenderMasksI;
    lender_conditions: FieldLenderConditionsI;
    lender_required: FieldLenderRequiredI;
    lender_options: FieldLenderOptionsI;
    lender_api_app: FieldLenderApiAppI;
    lender_web_app: FieldLenderWebAppI;
    lender_format: FieldLenderFormatI;
    lenders_included: string[];
  };
  final_details?: RootFieldDetailsI;
  default_details: RootFieldDetailsI;
}

export enum FieldDetails {
  'default_details' = 'default_details', // the default built
  'final_details' = 'final_details', // the store details based on their lenders/app settings
}

export enum FieldConditionTypes {
  'AND' = 'AND',
  'OR' = 'OR',
}

export enum FieldConditionActionTypes {
  'show' = 'show',
  'require' = 'require',
  'remove' = 'remove',
  'optional' = 'optional',
}

export enum FieldConditionRuleInputTypes {
  'number' = 'number',
  'test' = 'text',
  'textarea' = 'texarea',
  'radio' = 'radio',
  'checkbox' = 'checkbox',
  'select' = 'select',
}

export enum FieldConditionRuleTypeTypes {
  'string' = 'string',
  'integer' = 'integer',
  'double' = 'double',
  'date' = 'date',
  'time' = 'time',
  'datetime' = 'datetime',
  'boolean' = 'boolean',
}

export enum FieldConditionNumberOperatorTypes {
  'less' = 'less',
  'less_or_equal' = 'less_or_equal',
  'greater' = 'greater',
  'greater_or_equal' = 'greater_or_equal',
  'between' = 'between',
  'not_between' = 'not_between',
}

export enum FieldConditionAllOperatorTypes {
  'equal' = 'equal',
  'not_equal' = 'not_equal',
  'in' = 'in',
  'not_in' = 'not_in',
  'is_null' = 'is_null',
  'is_not_null' = 'is_not_null',
}

export enum FieldConditionStringOperatorTypes {
  'begins_with' = 'begins_with',
  'not_begins_with' = 'not_begins_with',
  'contains' = 'contains',
  'not_contains' = 'not_contains',
  'ends_with' = 'ends_with',
  'not_ends_with' = 'not_ends_with',
  'is_empty' = 'is_empty',
  'is_not_empty' = 'is_not_empty',
}

export interface FieldConditionRulesI {
  field: string;
  id: string;
  input: FieldConditionRuleInputTypes;
  operator: FieldConditionStringOperatorTypes | FieldConditionNumberOperatorTypes | FieldConditionAllOperatorTypes;
  type: FieldConditionRuleTypeTypes;
  value: string | boolean | number;
}

export interface FieldConditionsI {
  condition: FieldConditionTypes;
  not: boolean;
  rules: FieldConditionRulesI[];
  type: FieldConditionActionTypes;
  valid: boolean;
}

export enum ApplicationBuilderEditFormType {
  'advanced' = 'advanced',
  'default' = 'default',
}
