import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ProviderOfferLogicObjectI } from 'src/e2e-redesign/business-logic/e2e-configuration-object-logic';
import SingleLogo from 'src/e2e-redesign/components/SingleLogo';
import ThemedCard from 'src/e2e-redesign/components/ThemedCard';
import { AasoOffersToAcceptI } from 'src/interfaces/aaso.interfaces';
import { ApplicationSubmissionStateDto } from 'src/interfaces/submissions.interfaces';

interface StandardApprovalCardProps {
  lenderLogoDetails: any;
  submissionDetails: ApplicationSubmissionStateDto;
  offerDetails: AasoOffersToAcceptI;
  handleAcceptAndProceed: () => void;
  offerLogicObj: ProviderOfferLogicObjectI;
}

export default function StandardApprovalCard(props: StandardApprovalCardProps) {
  const theme = useTheme();
  const { offerLogicObj, lenderLogoDetails, submissionDetails, offerDetails, handleAcceptAndProceed } = props;
  const offer = offerDetails.offer;
  const renderDefinitionItem = (term: string, definition: string | number | undefined) => {
    if (definition === undefined || definition === '') return null;
    return (
      <>
        <Typography component="dt" variant="body1" style={{ color: theme.palette.text.primary }}>
          {term}
        </Typography>
        <Typography component="dd" variant="body1" style={{ color: theme.palette.primary.main }}>
          {definition}
        </Typography>
      </>
    );
  };

  return (
    <ThemedCard sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: '52px' }}>
        {lenderLogoDetails && <SingleLogo image={lenderLogoDetails} />}
      </Box>
      <dl style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '5px', marginBottom: '20px' }}>
        {renderDefinitionItem('Response', submissionDetails.lender_response)}
        {renderDefinitionItem('Approved Amount', offer.approved_amount)}
        {renderDefinitionItem('Term', offer.term)}
        {/* {renderDefinitionItem('Deferred Interest', response.deferredInterest)} */}
        {/* {renderDefinitionItem('Minimum Spend', response.minimumSpend)} */}
      </dl>
      {/* TODO: Add response.message conditionally here */}
      {offer.alternate_message && (
        <Typography variant="body1" style={{ margin: '20px' }} paragraph>
          {offer.alternate_message}
        </Typography>
      )}
      {offerLogicObj.show_approve_and_proceed && (
        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 'auto' }}>
          <Button variant="contained" color="success" fullWidth onClick={handleAcceptAndProceed}>
            Proceed
          </Button>
        </Box>
      )}
    </ThemedCard>
  );
}
