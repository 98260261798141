import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { e2eConfigLogic_buildProviderResponseLogicObject } from 'src/e2e-redesign/business-logic/e2e-configuration-object-logic';
import { E2eLogicObjectsWithAasoParams } from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import {
  E2eRoutingArgsI,
  handleE2eUserCompletedInitialSelectionRouting,
} from 'src/e2e-redesign/business-logic/e2e-routing-logic';
import { e2eSubLogic_handleAcceptAndProceed } from 'src/e2e-redesign/business-logic/e2e-submission-logic';
import SingleLogo from 'src/e2e-redesign/components/SingleLogo';
import ThemedCard from 'src/e2e-redesign/components/ThemedCard';
import { GroupedOfferI } from 'src/e2e-redesign/views/LenderResponses/logic/provider-response-logic';
import useE2eStore from 'src/e2e-store';
import { ApplicationStage } from 'src/enums/aaso.enums';
import { E2ERoutes } from 'src/enums/routes.enums';
import { ApiAutoApprovalCardDetailsI, LendersI } from 'src/interfaces/lenders.interfaces';
import { E2eLenderShowGetStatusI } from 'src/interfaces/store-settings.interfaces';
import {
  ApplicationSubmissionStateDto,
  AppSubLenderResponseE,
  AppSubStatusE,
  LenderOffersI,
} from 'src/interfaces/submissions.interfaces';
import { parseHtml } from 'src/utils/general';

import LenderResponseRow from './new-lender-response-row';

interface LenderOfferTableProps {
  groupedOffer: GroupedOfferI;
  index: number;
}

const borderlessTableStyle = {
  '& .MuiTableCell-root': {
    border: 'none',
    // width: '100%',
  },
};

export const tableCellStyle = {
  whiteSpace: 'nowrap',
  width: '1%',
};

const AutoApprovalCardTable: React.FC<LenderOfferTableProps> = (props: LenderOfferTableProps) => {
  const { groupedOffer, index } = props;
  const offerLender: LendersI = groupedOffer.lender;
  const nav = useNavigate();
  const approvedOffers = groupedOffer.autoApprovals[0];
  const lenderDisclosures = approvedOffers.lender_disclosures;
  const autoApprovalObj = offerLender.api_pipe_settings.e2e_config?.auto_approval;
  const cardDetails = autoApprovalObj?.card_details;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getLenderLogo = () => {
    if (offerLender.logo_url) {
      return {
        src: offerLender.logo_url,
        alt: offerLender.name,
      };
    } else {
      if (lenderDisclosures && lenderDisclosures?.length > 0) {
        const disc = lenderDisclosures.find((ld) => ld.logo.length > 0);
        if (disc) {
          return {
            src: disc.logo,
            alt: offerLender.name,
          };
        }
      }
    }
    return false;
  };

  const lenderLogoDetails = getLenderLogo();

  if (!cardDetails) return <></>;

  const fixedHeaderStyle = {
    position: 'sticky',
    top: 0,
    backgroundColor: 'background.paper',
    zIndex: 2,
  };

  const renderMobileRow = (label: string, value: React.ReactNode) => {
    let styleLabel = {
      width: '1%',
      justifyContent: 'center',
      fontWeight: 'initial',
      fontSize: '1rem',
    };
    const styleValue = {
      width: '1%',
      justifyContent: 'center',
      fontWeight: 'bold',
      fontSize: '1rem',
    };
    if (label.includes('Offer') && label !== 'Offer Name') {
      styleLabel = {
        ...styleLabel,
        fontWeight: 'bold',
        fontSize: '1.2rem',
      };
    }

    return (
      <TableRow>
        <TableCell sx={styleLabel} component="th" scope="row">
          {label.toUpperCase()}
        </TableCell>
        <TableCell sx={styleValue} align="right">
          {value}
        </TableCell>
      </TableRow>
    );
  };

  const showNormalOrMobile = () => {
    if (isMobile) {
      return (
        <>
          {renderMobileRow('Lender Response', cardDetails?.lender_response || 'N/A')}
          {renderMobileRow('Initial Payment', cardDetails?.initial_payment || 'N/A')}
          {renderMobileRow('Term', cardDetails?.term || 'N/A')}
          {renderMobileRow('APR', cardDetails?.apr || 'N/A')}
          {renderMobileRow('Payment Amount', cardDetails?.payment_amount || 'N/A')}
        </>
      );
    } else {
      return (
        <>
          <TableRow>
            <TableCell align="center" style={tableCellStyle}>
              {cardDetails.lender_response}
            </TableCell>
            <TableCell align="center" style={tableCellStyle}>
              {cardDetails.initial_payment}
            </TableCell>
            <TableCell align="center" style={tableCellStyle}>
              {cardDetails.term}
            </TableCell>
            <TableCell align="center" style={tableCellStyle}>
              {cardDetails.apr}
            </TableCell>
            <TableCell align="center" style={tableCellStyle}>
              {cardDetails.payment_amount}
            </TableCell>
          </TableRow>
        </>
      );
    }
  };

  const showDescription = () => {
    if (cardDetails.description && cardDetails.description.length > 0) {
      return (
        <Typography variant="body1" style={{ margin: '20px' }} paragraph>
          {parseHtml(cardDetails.description)}
        </Typography>
      );
    }
    return null;
  };

  return (
    <Grid item xs={12} key={index}>
      <ThemedCard sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            height: '52px',
            marginBottom: '16px',
            '& img': {
              maxHeight: '100%',
              maxWidth: '100%',
              objectFit: 'contain',
            },
          }}
        >
          {lenderLogoDetails && <SingleLogo image={lenderLogoDetails} />}
        </Box>
        <Box
          component="dl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            marginBottom: '20px',
            overflowX: 'auto',
          }}
        >
          <TableContainer
            sx={{
              ...borderlessTableStyle,
              ...fixedHeaderStyle,
            }}
          >
            {!isMobile && (
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ ...tableCellStyle, ...fixedHeaderStyle }}>
                    Lender Response
                  </TableCell>
                  <TableCell align="center" sx={{ ...tableCellStyle, ...fixedHeaderStyle }}>
                    Initial Payment
                  </TableCell>
                  <TableCell align="center" sx={{ ...tableCellStyle, ...fixedHeaderStyle }}>
                    Term
                  </TableCell>
                  <TableCell align="center" sx={{ ...tableCellStyle, ...fixedHeaderStyle }}>
                    APR
                  </TableCell>
                  <TableCell align="center" sx={{ ...tableCellStyle, ...fixedHeaderStyle }}>
                    Payment Amount
                  </TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {showNormalOrMobile()}
              {/* <LenderResponseRow */}
              {/*   card_details={offer} */}
              {/*   selectedOffer={selectedOffer} */}
              {/*   onSelect={(fpUniqueId: string) => handleOfferSelection(fpUniqueId)} */}
              {/*   displayFields={displayFields} */}
              {/*   isPending={isPending} */}
              {/* /> */}
            </TableBody>
          </TableContainer>
          {showDescription()}
        </Box>
        <Box style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: '20px' }}>
          <Button
            variant="contained"
            color="success"
            onClick={() => nav('/dashboard')}
            sx={{ width: isMobile ? '100%' : '25vw', maxWidth: '100%' }}
          >
            Proceed
          </Button>
        </Box>
      </ThemedCard>
    </Grid>
  );
};

export default AutoApprovalCardTable;
