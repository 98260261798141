import { isEmpty } from 'lodash';
import {
  e2eLogic_DoMultiplePathsExist,
  e2eLogic_getCurrentE2eForm,
  e2eLogic_getCurrentE2eFormPath,
} from 'src/e2e-redesign/business-logic/e2e-business-logic';
import { E2eLogicObjectsWithAasoParams } from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import { Logo } from 'src/e2e-redesign/components/LogoGrid';
import { NotOffersI } from 'src/e2e-redesign/views/LenderResponses/logic/provider-response-logic';
import { AASO } from 'src/interfaces/aaso.interfaces';
import {
  E2eAppSettingsFormPathsI,
  E2eAppSettingsFormsI,
  E2eAppSettingsPathConditionsI,
  E2eAppSettingsScreenDetailsI,
} from 'src/interfaces/application-settings.interfaces';
import { Disclosure } from 'src/interfaces/disclosures.interfaces';
import {
  AppLogicObjectsResponseI,
  E2eAppConfigurationsI,
  E2eGlobalStore,
  E2eLenderSettingsI,
} from 'src/interfaces/e2e-state.interfaces';
import { E2eConfigI, LendersI } from 'src/interfaces/lenders.interfaces';
import {
  ApplicationSubmissionStateDto,
  AppSubLenderResponseE,
  AppSubStatusE,
  LenderOffersI,
} from 'src/interfaces/submissions.interfaces';

export interface ProviderOffersI {
  sub_details: ApplicationSubmissionStateDto;
  offer_details?: LenderOffersI;
  lender: LendersI;
  lender_disclosures?: Disclosure[];
  is_offer: boolean;
  is_auto?: boolean;
}

/**
 * This is what controls how the page is rendered and what actions are availble to the user
 */
export interface ProviderOfferLogicObjectI {
  show_approve_and_proceed: boolean;
}
/**
 * What are the logic objects?
 *  - lenders.api_pipe_settings.e2e_config
 *      - right now all we have is after_form_submission_redirect
 *  - application_settings.e2e - there's a lot here
 *      - e2e_forms
 *          - details
 *          - id = part of the url
 *          - path_conditions
 *          - paths
 *              - form_details
 *              - lender_type
 *              - lenders
 *              - required_fields
 *              - submission_type
 *              - user_lender_list
 *              - description
 *              - lenders
 *      - splash_screen_details
 *      - thank_you_page_details
 *  - store_details
 *      - managed_processor_id
 *      - is_laa
 *      - laa_lender_id
 *      - franchise_id
 *      - org_id
 *  - store_settings.e2e_acl_configurations
 *      - processor_e2e_configuration
 *          - thank_you_page_details
 *      - roles_e2e_configuration
 *          - lender_details
 *              - name, description - replaces the name
 *          - paths
 *              - pre_form_disclosures
 *      - franchise_e2e_configuration
 *          - lender_details
 *              - after_form_submission_redirect - this ties to lender object
 *
 * These functions are meant to help get to logic objects
 * @param routingPackage
 */

export const e2eConfigLogic_buildAppConfigurationObject = (
  logicObjects: AppLogicObjectsResponseI,
  aaso: AASO,
  e2e_form_id: string,
): E2eAppConfigurationsI => {
  // R:TODO E2E P0 - this makes a lot of functions obsolete
  /**
   * we're updating the config object everytime a user moves to a new page
   * and this object returns all of the relevant information that is needed to present
   * or handle logic on a page
   *
   *
   */
  let current_form: any;
  // TODO E2E P0 - TEST THIS VERY VERY WELL
  /**
   * NOTE: about using aaso.e2e_form_snapshot
   * If the merchant changes the form while an applicant is filling out a form
   * it could potentially break the form for the user so to prevent this,
   * if a snapshot exists of the form, we will be using the snapshot which marks
   * the form at the time the applicant filled out the form
   */
  if (aaso && aaso.e2e_form_snapshot) {
    // use existing snapshot so it doesn't mess up if the merchant changes the form while someone is filling it out
    current_form = aaso.e2e_form_snapshot;
    const cpaths = current_form.paths;
    const newForm = e2eLogic_getCurrentE2eForm(logicObjects.application_settings, e2e_form_id);
    if (newForm && cpaths) {
      // adding this, so if we do update for form_fields, they will be updated while keeping the form structure in-tact
      cpaths.forEach((c: any, i: number) => {
        const found = newForm.paths.find((n) => n.id === c.id);
        if (found) {
          current_form.paths[i].form_details = found.form_details;
          current_form.paths[i].required_fields = found.required_fields;
        }
      });
    }
  } else {
    // new form
    current_form = e2eLogic_getCurrentE2eForm(logicObjects.application_settings, e2e_form_id);
  }
  const current_path = e2eLogic_getCurrentE2eFormPath(aaso, current_form);

  let path_conditions;
  let lender_e2e_settings;
  let thank_you_page_details;
  let splash_screen_details;
  if (current_form) {
    path_conditions = e2eConfigLogic_getPathConditionObjects(aaso, current_form);
  }
  if (current_form && current_path) {
    lender_e2e_settings = e2eConfigLogic_buildLenderE2eFormSettings(aaso, current_form, current_path, logicObjects);
    thank_you_page_details = e2eConfigLogic_buildThankYouPageDetails(aaso, current_form, current_path, logicObjects);
    splash_screen_details = e2eConfigLogic_buildSplashPageDetails(aaso, current_form, current_path, logicObjects);
  }

  return {
    multi_paths_exist: e2eLogic_DoMultiplePathsExist(logicObjects.application_settings, e2e_form_id),
    current_form,
    current_path,
    outside_form_details: logicObjects.outside_form_details,
    store_settings: logicObjects.store_settings,
    path_conditions,
    store_details: logicObjects.store_details,
    lender_e2e_settings,
    thank_you_page_details,
    splash_screen_details,
    processor_details: logicObjects.processor_details,
    lender_ids: logicObjects.lender_ids,
    lenders: logicObjects.lenders,
    laa_settings: logicObjects.laa_settings,
    franchise_settings: logicObjects.franchise_settings,
    logo_url: logicObjects.logo_url,
  };
};
export const e2eConfigLogic_buildProviderResponseLogicObject = (
  providerOffer: ProviderOffersI,
): ProviderOfferLogicObjectI => {
  const { sub_details } = providerOffer;
  const obj: ProviderOfferLogicObjectI = {
    show_approve_and_proceed: false,
  };
  const validResponsesForApproveAndProceed = [
    AppSubLenderResponseE.approved,
    AppSubLenderResponseE.e2e_pre_approved,
    AppSubLenderResponseE.pre_approved,
  ];
  if (validResponsesForApproveAndProceed.includes(sub_details.lender_response)) {
    obj.show_approve_and_proceed = true;
  }
  // also account for ApplicationSubmissionStateEnums
  return obj;
};

export const e2eConfigLogic_buildStoreLenderLogoObjects = (
  app_configs: E2eAppConfigurationsI,
  disclosures: Disclosure[],
): Logo[] => {
  let lender_ids: number[] = app_configs.lender_ids;
  if (app_configs?.current_path?.lenders) {
    lender_ids = app_configs.current_path.lenders;
  }
  if (!lender_ids) {
    // TODO E2E P2 - handle no lender ids found...
  }
  const dls = lender_ids
    .map((lender_id) => {
      const disclosure = disclosures.find((disclosure) => disclosure.lender_id === lender_id);
      let logo: Logo;
      app_configs.lenders.find((lender) => {
        if (lender.lender_id === lender_id) {
          logo = {
            src: lender.logo_url ?? '',
            alt: lender.name,
          };
          return logo;
        }
      });
      if (disclosure) {
        logo = {
          src: disclosure?.logo ?? '',
          alt: disclosure?.name ?? '',
        };
        return logo;
      }
    })
    .filter((logo) => logo !== undefined) as Logo[];

  return dls;
};

export const e2eConfigLogic_parseTemplate = (
  template: string,
  variables: Record<string, string | number | boolean>,
): string => {
  return template.replace(/\{\{(\w+)\}\}/g, (match, key) => {
    const value = variables[key];
    return value !== undefined ? String(value) : match;
  });
};
export const e2eConfigLogic_buildAppOffersObject = (
  submissions: ApplicationSubmissionStateDto[],
  lenders: LendersI[],
  disclosures: Disclosure[],
): ProviderOffersI[] => {
  const provOff: ProviderOffersI[] = [];
  // const lenders = e2eStore.lenders;
  // const disclosures = e2eStore.disclosures;
  const validSubmissionStates = [AppSubStatusE.lender_responded];
  // const validProivderResponses = [
  //   ApplicationSubmissionLenderStatusEnums.e2e_pre_approved,
  //   ApplicationSubmissionLenderStatusEnums.approved,
  //   ApplicationSubmissionLenderStatusEnums.pre_approved,
  //   ApplicationSubmissionLenderStatusEnums.rejected,
  //   ApplicationSubmissionLenderStatusEnums.soft_decline
  // ];
  // NOTE E2E - we're just going to show all offers until they tell us otherwise
  const validProivderResponses = Object.values(AppSubLenderResponseE);
  const acceptedLenderResponses = [
    AppSubLenderResponseE.approved,
    AppSubLenderResponseE.e2e_pre_approved,
    AppSubLenderResponseE.pre_approved,
    AppSubLenderResponseE.contract_sent,
  ];
  if (lenders) {
    for (const sub of submissions) {
      const lenderResponse = sub.lender_response;
      const submissionState = sub.submission_state;
      if (validProivderResponses.includes(lenderResponse) && lenderResponse !== AppSubLenderResponseE.invalid) {
        const lender = lenders.find((lender) => lender.lender_id == sub.lender_id);
        if (lender) {
          const lender_disclosures = disclosures.filter((d) => d.lender_id == lender?.lender_id);
          if (
            sub.lender_offers &&
            sub.lender_offers.length > 0 &&
            acceptedLenderResponses.includes(sub.lender_response)
          ) {
            for (const offer of sub.lender_offers) {
              const offerObject: ProviderOffersI = {
                sub_details: sub,
                offer_details: offer,
                lender,
                lender_disclosures,
                is_offer: true,
              };
              provOff.push(offerObject);
            }
          }
          // provOff.push(offerObject);
        }
      }
    }
  }
  return provOff;
};

const e2eConfigLogic_showLenderCard = (lender: LendersI, sub: ApplicationSubmissionStateDto) => {
  const applicantUiConfigs = lender?.api_pipe_settings?.e2e_config?.config?.ui?.applicant;
  if (applicantUiConfigs) {
    const lenderResponse = applicantUiConfigs?.lender_response;
    if (lenderResponse) {
      const key = lenderResponse.key;
      if (sub.lender_response === key) {
        const doNotShow = lenderResponse?.offers?.do_not_show;
        if (doNotShow) {
          return false;
        }
      }
    }
  }
  return true;
};
export const e2eConfigLogic_buildNotOffers = (
  submissions: ApplicationSubmissionStateDto[],
  lenders: LendersI[],
  disclosures: Disclosure[],
): NotOffersI[] => {
  const provOff: NotOffersI[] = [];
  // const lenders = e2eStore.lenders;
  // const disclosures = e2eStore.disclosures;
  // NOTE E2E - we're just going to show all offers until they tell us otherwise
  // const validProivderResponses = Object.values(AppSubLenderResponseE);
  const validProivderResponses = [
    AppSubLenderResponseE.rejected,
    AppSubLenderResponseE.soft_decline,
    AppSubLenderResponseE.pending,
    AppSubLenderResponseE.hold,
  ];

  if (lenders) {
    for (const sub of submissions) {
      const lenderResponse = sub.lender_response;
      const submissionState = sub.submission_state;
      if (validProivderResponses.includes(lenderResponse)) {
        const lender = lenders.find((lender) => lender.lender_id == sub.lender_id);
        if (lender) {
          const showCard = e2eConfigLogic_showLenderCard(lender, sub);
          if (showCard) {
            const lender_disclosures = disclosures.filter((d) => d.lender_id == lender?.lender_id);
            const offerObject: NotOffersI = {
              sub_details: sub,
              lender,
              lender_disclosures,
              is_offer: false,
            };
            provOff.push(offerObject);
          }
        }
      }
    }
  }
  return provOff;
};

export const e2eConfigLogic_buildThankYouPageDetails = (
  aaso: AASO,
  e2eForm: E2eAppSettingsFormsI,
  e2eFormPath: E2eAppSettingsFormPathsI,
  logicObjects: AppLogicObjectsResponseI,
): E2eAppSettingsScreenDetailsI => {
  // R:TODO E2E P1 - refactor the thank_you_page - it's being handling in a couple of places
  const appSettings = logicObjects.application_settings;
  const storeSettings = logicObjects.store_settings;
  if (aaso && e2eForm && e2eFormPath) {
    const thankYouPageStore = appSettings?.e2e?.thank_you_page_details;
    const selectedThankYouPageId = e2eForm.details.thank_you_page_id;
    if (thankYouPageStore) {
      const defaultThankYouPageDetails = thankYouPageStore.find((t) => t.id === selectedThankYouPageId);
      // now we need to check if there are any overrides
      const aclConfig = storeSettings.e2e_acl_configurations;
      const franchConfig = aclConfig?.franchise_e2e_configuration;
      const processorConfig = aclConfig?.processor_e2e_configuration;
      // const rolesConfig = aclConfig?.roles_e2e_configuration;
      const franchConfigThankYou = franchConfig?.thank_you_page_details;
      const processorConfigThankYou = processorConfig?.thank_you_page_details;

      if (defaultThankYouPageDetails) {
        if (franchConfigThankYou?.enabled) {
          defaultThankYouPageDetails.message = franchConfigThankYou.message
            ? franchConfigThankYou.message
            : defaultThankYouPageDetails.message;
          defaultThankYouPageDetails.header = franchConfigThankYou.header
            ? franchConfigThankYou.header
            : defaultThankYouPageDetails.header;
          defaultThankYouPageDetails.body = franchConfigThankYou.body
            ? franchConfigThankYou.body
            : defaultThankYouPageDetails.body;
          defaultThankYouPageDetails.disclosure = franchConfigThankYou.disclosure
            ? franchConfigThankYou.disclosure
            : defaultThankYouPageDetails.disclosure;
        }
        if (processorConfigThankYou?.enabled) {
          defaultThankYouPageDetails.message = processorConfigThankYou.message
            ? processorConfigThankYou.message
            : processorConfigThankYou.message;
          defaultThankYouPageDetails.header = processorConfigThankYou.header
            ? processorConfigThankYou.header
            : processorConfigThankYou.header;
          defaultThankYouPageDetails.body = processorConfigThankYou.body
            ? processorConfigThankYou.body
            : processorConfigThankYou.body;
          defaultThankYouPageDetails.disclosure = processorConfigThankYou.disclosure
            ? processorConfigThankYou.disclosure
            : defaultThankYouPageDetails.disclosure;
        }
        return defaultThankYouPageDetails;
      }
    }
    /**
     * Thank you page details can come from:
     * - app_settings.e2e.thank_you_page_details
     * - store_settings.e2e_acl_configurations (franchise_e2e_configuration, processor_e2e_configuration or roles_e2e_configuration
     *
     * What determines which thank you page to use:
     * - app_settings.e2e.e2e_forms.?.details.thank_you_page_id = is the defauled
     * - but if there are thank_you_page_details in any of the store_settings acl object, this overrides it
     */
  }
  return {
    message: 'FormPiper default thank you message',
    id: 'default',
    body: 'formpiper default body ',
    header: 'formpiper default header',
    disclosure: 'formpiper default disclosure',
  };
};

export const e2eConfigLogic_buildSplashPageDetails = (
  aaso: AASO,
  e2eForm: E2eAppSettingsFormsI,
  e2eFormPath: E2eAppSettingsFormPathsI,
  logicObjects: AppLogicObjectsResponseI,
): E2eAppSettingsScreenDetailsI => {
  const appSettings = logicObjects.application_settings;
  const storeSettings = logicObjects.store_settings;
  if (aaso && e2eForm && e2eFormPath) {
    const thankYouPageStore = appSettings?.e2e?.thank_you_page_details;
    const selectedThankYouPageId = e2eForm.details.thank_you_page_id;
    if (thankYouPageStore) {
      const defaultThankYouPageDetails = thankYouPageStore.find((t) => t.id === selectedThankYouPageId);
      // now we need to check if there are any overrides
      const aclConfig = storeSettings.e2e_acl_configurations;
      const franchConfig = aclConfig?.franchise_e2e_configuration;
      const processorConfig = aclConfig?.processor_e2e_configuration;
      const rolesConfig = aclConfig?.roles_e2e_configuration;
      const franchConfigThankYou = franchConfig?.thank_you_page_details;
      const processorConfigThankYou = processorConfig?.thank_you_page_details;

      if (defaultThankYouPageDetails) {
        if (franchConfigThankYou?.enabled) {
          defaultThankYouPageDetails.message = franchConfigThankYou.message
            ? franchConfigThankYou.message
            : defaultThankYouPageDetails.message;
          defaultThankYouPageDetails.header = franchConfigThankYou.header
            ? franchConfigThankYou.header
            : defaultThankYouPageDetails.header;
          defaultThankYouPageDetails.body = franchConfigThankYou.body
            ? franchConfigThankYou.body
            : defaultThankYouPageDetails.body;
          defaultThankYouPageDetails.disclosure = franchConfigThankYou.disclosure
            ? franchConfigThankYou.disclosure
            : defaultThankYouPageDetails.disclosure;
        }
        if (processorConfigThankYou?.enabled) {
          defaultThankYouPageDetails.message = processorConfigThankYou.message
            ? processorConfigThankYou.message
            : processorConfigThankYou.message;
          defaultThankYouPageDetails.header = processorConfigThankYou.header
            ? processorConfigThankYou.header
            : processorConfigThankYou.header;
          defaultThankYouPageDetails.body = processorConfigThankYou.body
            ? processorConfigThankYou.body
            : processorConfigThankYou.body;
          defaultThankYouPageDetails.disclosure = processorConfigThankYou.disclosure
            ? processorConfigThankYou.disclosure
            : defaultThankYouPageDetails.disclosure;
        }
        return defaultThankYouPageDetails;
      }
    }

    /**
     * Thank you page details can come from:
     * - app_settings.e2e.thank_you_page_details
     * - store_settings.e2e_acl_configurations (franchise_e2e_configuration, processor_e2e_configuration or roles_e2e_configuration
     *
     * What determines which thank you page to use:
     * - app_settings.e2e.e2e_forms.?.details.thank_you_page_id = is the defauled
     * - but if there are thank_you_page_details in any of the store_settings acl object, this overrides it
     */
  }
  return {
    message: 'FormPiper default thank you message',
    id: 'default',
    body: 'formpiper default body ',
    header: 'formpiper default header',
    disclosure: 'formpiper default disclosure',
  };
};

export const e2eConfigLogic_buildLenderE2eFormSettings = (
  aaso: AASO,
  e2eForm: E2eAppSettingsFormsI,
  e2eFormPath: E2eAppSettingsFormPathsI,
  logicObjects: AppLogicObjectsResponseI,
): E2eLenderSettingsI | undefined => {
  if (aaso && e2eForm && e2eFormPath) {
    // once we know the path, we can update the app_configurations object
    const obj: any = {};
    if (e2eFormPath) {
      const pathLenders = e2eFormPath.lenders;
      for (const l of pathLenders) {
        const d = e2eConfigLogic_checkLenderConfigurationsByLenderId(aaso, e2eForm, e2eFormPath, logicObjects, l);
        // TODO ALEX - where form.lender_details is being used
        // not an issue it's just overwriting the object which we are not using
        const lender_updated_details = e2eForm?.lender_details?.find(
          (ld) => ld.lender_id === l && ld.path_id === e2eFormPath.id,
        );
        if (!isEmpty(d)) {
          obj[l] = {
            ...d,
            lender_updated_details,
          };
        }
      }
    }
    return obj;
  }
};

export const e2eConfigLogic_doesCorrespondingLenderObjectExist = (lender_details: any, e2e_config: any) => {
  if (lender_details && e2e_config) {
    const ldKeys = Object.keys(lender_details);
    const e2eKeys = Object.keys(e2e_config);
    const lenderDetails: any = {};
    e2eKeys.forEach((key) => {
      if (lender_details[key]) {
        lenderDetails[key] = lender_details[key];
      }
    });
    return lenderDetails;
  }
};
export const e2eConfigLogic_checkLenderConfigurationsByLenderId = (
  aaso: AASO,
  e2eForm: E2eAppSettingsFormsI,
  e2eFormPath: E2eAppSettingsFormPathsI,
  logicObjects: AppLogicObjectsResponseI,
  lender_id: number,
) => {
  const { lenders, store_settings } = logicObjects;
  if (aaso && e2eForm && e2eFormPath) {
    const obj: any = {};
    try {
      if (e2eFormPath) {
        const e2eAclConfigurations = store_settings.e2e_acl_configurations;
        const lenderE2eConfigurations = e2eConfigLogic_getLenderE2eConfigObjects(lenders);
        const lenderE2eConfiguration = lenderE2eConfigurations[lender_id];
        if (lenderE2eConfiguration) {
          const lenderE2eConfig = lenderE2eConfigurations;
          if (lenderE2eConfig && e2eAclConfigurations) {
            const e2eAclConfigKeys = Object.keys(e2eAclConfigurations);
            e2eAclConfigKeys.forEach((key: string) => {
              // @ts-ignore
              const e2eAclConfig = e2eAclConfigurations[key];
              if (e2eAclConfig && e2eAclConfig.lender_details) {
                const lenderDetails = e2eAclConfig.lender_details;
                if (lenderE2eConfigurations[lender_id]) {
                  const lender_object_e2e_config = lenderE2eConfigurations[lender_id];
                  const e2e_config = e2eAclConfig.lender_details[lender_id];
                  const lender_details = lender_object_e2e_config.config?.lender_details;
                  const matchingLenderDetails = e2eConfigLogic_doesCorrespondingLenderObjectExist(
                    lender_details,
                    e2e_config,
                  );
                  if (matchingLenderDetails) {
                    obj[key] = {
                      lender_object_e2e_config: matchingLenderDetails,
                      e2e_config,
                    };
                  }
                }
              }
            });
          }
        }
      }
      return obj;
    } catch (e) {
      console.log(e);
    }
  }
  // first check store_settings... does it have any e2e_acl_configurations?
  // if so, search through, franchise_e2e_configuration, processor_e2e_configuration, roles_e2e_configuration
  // use the key to handle the logic... fuck
  // we need to first check, what path they are submitting for
  // What lenders are included in this path
  // then check all lender objects to see if they have e2e_config objects
  // if they have e2e_config object, check for redirect
};

export interface E2eLenderConfigurationsI {
  [lender_id: string]: E2eConfigI;
}
export const e2eConfigLogic_getLenderE2eConfigObjects = (lenders: LendersI[]): E2eLenderConfigurationsI => {
  const lender_e2e_config: E2eLenderConfigurationsI = {};
  if (lenders) {
    for (const lender of lenders) {
      if (lender.api_pipe_settings?.e2e_config) {
        lender_e2e_config[lender.lender_id] = lender.api_pipe_settings?.e2e_config;
      }
    }
  }
  return lender_e2e_config;
};

export interface E2eLogicObjPathConditionsI {
  e2e_form: E2eAppSettingsFormsI;
  current_path: E2eAppSettingsFormPathsI;
  paths: E2eAppSettingsFormPathsI[];
  path_conditions: E2eAppSettingsPathConditionsI[];
  current_path_conditions: E2eAppSettingsPathConditionsI;
}
export const e2eConfigLogic_getPathConditionObjects = (
  aaso: AASO,
  e2eForm: E2eAppSettingsFormsI,
): E2eLogicObjPathConditionsI | undefined => {
  if (e2eForm) {
    const currentPath = e2eForm.paths.find((path) => path.id === aaso.current_path_id);
    if (currentPath) {
      const pathConditions = e2eForm.path_conditions;
      const currentPathConditionSettings = pathConditions.find((path) => path.start_path === currentPath.id);
      if (currentPathConditionSettings) {
        return {
          e2e_form: e2eForm,
          current_path: currentPath,
          path_conditions: pathConditions,
          paths: e2eForm.paths,
          current_path_conditions: currentPathConditionSettings,
        };
      }
    }
  }
};

export const e2eConfigLogic_testCheckStats = (routingPackage: E2eLogicObjectsWithAasoParams) => {
  // FOR TESTING - to see where everything is
  const { e2eStore, auth, aasoContext, params } = routingPackage;
  const { store_uuid, e2e_form_id, aaso_id } = params;
  // HOWTO - DEV - set to true to get current state of things
  const log = false;

  const appConfigurations = e2eStore.app_configurations;
  const storeSettings = e2eStore.store_settings;
  const appSettings = e2eStore.application_settings;
  const outsideFormDetails = e2eStore.outside_form_details;
  const form_values = e2eStore.form_values;
  const storeDetails = e2eStore.store_details;
  const signedDisclosures = e2eStore.aaso.disclosures;
  const currentForm = e2eStore?.application_settings?.e2e?.e2e_forms?.find((form) => form.id === e2e_form_id);
  const currentStage = e2eStore.aaso.current_stage;
  // console.log('App Configurations:', appConfigurations);
  // console.log('form values', form_values);
  if (log) {
    console.log('Current Stage:', currentStage);
    console.log('Store Settings:', storeSettings);
    console.log('App Settings:', appSettings);
    console.log('Outside Form Details:', outsideFormDetails);
    console.log('Store details:', storeDetails);
  }
  if (currentForm && log) {
    console.log('Curent Form:', currentForm);
    const currentPath = currentForm.paths.find((path) => path.id === e2eStore.aaso.current_path_id);
    if (currentForm && log) {
      console.log('Current path:', currentPath);
    }
  }

  if (signedDisclosures?.length > 0 && log) {
    console.log('Signed Disclosures:', signedDisclosures);
  }
};
