import {
  e2eLogic_getValidE2eLenders,
  e2eLogic_getValidE2eLendersForAutoApproval,
} from 'src/e2e-redesign/business-logic/e2e-business-logic';
import { E2ePathSubmissionTypesE } from 'src/enums/e2e.enums';
import { LenderTypesE } from 'src/enums/lenders.enums';
import { FieldDetailI } from 'src/interfaces/application.interfaces';

export interface ApplicationSettingsI {
  unique_id: string;
  store_id: number;
  org_id: number;
  default: ApplicationDefaultSettingsI;
  easy: ApplicationEasySettingsI;
  advanced?: ApplicationAdvancedSettingsI;
  e2e?: E2eAppSettingsI;
  lender_order?: number[];
}

export interface ApplicationDefaultSettingsI {
  selected: boolean;
  times_used: number;
  required_fields?: string[];
  removed_fields?: string[];
  form_details?: any;
  user_lender_list?: any[];
}

export interface ApplicationEasySettingsI {
  selected: boolean;
  lender_ids: number[];
  times_used: number;
  required_fields?: string[];
  removed_fields?: string[];
  form_details?: FieldDetailI[];
  user_lender_list?: any[];
}

export interface AdvancedCategoriesI {
  id?: string;
  name: string;
  description: string;
  lender_ids: number[];
  times_used: number;
  required_fields?: string[];
  removed_fields?: string[];
  form_details?: any;
  user_lender_list?: any[];
}

export interface ApplicationAdvancedSettingsI {
  selected: boolean;
  categories: AdvancedCategoriesI[];
  times_used: number;
}

export interface E2eAppSettingsI {
  selected: boolean;
  paths: E2eAppSettingsPathsI[];
  e2e_forms: E2eAppSettingsFormsI[];
  thank_you_page_details: E2eAppSettingsScreenDetailsI[];
  splash_screen_details: E2eAppSettingsScreenDetailsI[];
  times_used: number;
}

export interface E2eAppSettingsPathsI {
  id?: string;
  name: string;
  enabled: boolean;
  description: string;
  lender_ids: number[];
  times_used: number;
  required_fields?: string[];
  removed_fields?: string[];
  form_details?: any;
  user_lender_list?: any[];
}

export interface E2eAppSettingsScreenDetailsI {
  id: string;
  message?: string;
  body?: string;
  header?: string;
  disclosure?: string;
}

export interface E2eAppSettingsNotificationDetailsI {
  emails_after_form_submitted?: string[];
  processor_submit_after_e2e?: string[];
}

export enum LogoObjectsE {
  'org' = 'org',
  'store' = 'store',
  'laa_settings' = 'laa_settings',
  'franchise_settings' = 'franchise_settings',
}

export interface LogoConfigI {
  object_type: LogoObjectsE;
  field_name: string;
}

export interface E2eLogoDetailsI {
  enabled: boolean;
  logo_url?: string;
  logo_config: LogoConfigI;
}

export interface E2eAppSettingsFormDetailsI {
  internal_name: string;
  name: string;
  description: string;
  logo_details?: E2eLogoDetailsI;
  thank_you_page_id: string;
}

export interface E2eAppSettingsFormsI {
  id: string;
  notification_details: E2eAppSettingsNotificationDetailsI;
  details: E2eAppSettingsFormDetailsI;
  paths: E2eAppSettingsFormPathsI[];
  lender_details: E2eAppSettingsLenderDetailsI[];
  path_conditions: E2eAppSettingsPathConditionsI[];
}

export interface FormLenderDetailsI {
  lender_api_app: {
    [lender_id: number]: boolean;
  };
  lender_conditions: {
    [lender_id: number]: boolean;
  };
  lender_format: {
    [lender_id: number]: boolean;
  };
  lender_masks: {
    [lender_id: number]: boolean;
  };
  lender_options: {
    [lender_id: number]: boolean;
  };
  lender_required: {
    [lender_id: number]: boolean;
  };
  lender_web_app: {
    [lender_id: number]: boolean;
  };
  lenders_included: string[];
}
export interface FormDefaultDetailsI {
  all_options?: any[];
  base_options?: any[];
  component?: string;
  disclosure?: string;
  enum?: string;
  faker?: any;
  is_verification?: boolean;
  label: string;
  mask?: string;
  msg?: string;
  name: string;
  order: number;
  params?: any;
  required: boolean;
  specialDisclosure?: string;
  type: string;
  validation?: string[];
}

export interface FormDetailsI {
  always_required: boolean;
  default_details: FormDefaultDetailsI[];
  final_details: FormDefaultDetailsI[];
  id: string;
  lender_details: FormLenderDetailsI;
  section: string;
}
export interface E2eAppSettingsFormPathsI {
  id: string;
  name: string;
  description: string;
  button_text: string;
  sub_title?: string;
  enabled: boolean;
  submission_type: E2ePathSubmissionTypesE;
  waterfall_conditions: E2eWaterfallConditionsE[] | E2eWaterfallConditionsE;
  form_details?: FormDetailsI[];
  required_fields?: string[];
  removed_fields?: string[];
  user_lender_list?: any[];
  lender_type: LenderTypesE[];
  pre_form_disclosures: string[];
  lenders: number[];
  lender_details: E2eAppSettingsLenderDetailsI[];
}

export enum E2eWaterfallConditionsE {
  'stop_at_first' = 'stop_at_first', // Stop when approval amount meets requested amount
  'stop_in_aggregate' = 'stop_in_aggregate', // Stop when aggregate approval amount meets requested amount
  'stop_on_pending' = 'stop_on_pending', // will stop if lender comes back as pending
  'stop_on_pending_w_option_to_continue' = 'stop_on_pending_w_option_to_continue', // will stop if lender comes back as pending, but will have option to continue
  'stop_on_pending_w_option_to_continue_next_path' = 'stop_on_pending_w_option_to_continue_next_path', // will stop if lender comes back as pending, but will have option to continue next paths, but not the current path
}

export interface E2eAppSettingsLenderDetailsI {
  path_id: string;
  lender_id: number;
  order: number;
  name: string;
  description: string;
  is_e2e: boolean;
  auto_submit: boolean;
  display_offers: boolean;
}

export enum E2ePathConditionsE {
  'all_failed' = 'all_failed',
  'x_failed' = 'x_failed',
  'full_amount_of_money_first_lender' = 'full_amount_of_money_first_lender',
  'stack_full_amount_of_money_reached' = 'stack_full_amount_of_money_reached',
  'always_show' = 'always_show',
}

export interface E2eAppSettingsPathConditionsI {
  index: number;
  start_path: string;
  if_path: string;
  else_path: string;
  condition: E2ePathConditionsE;
  if_splash: string;
  else_splash: string;
  percent?: number;
}
