import * as React from 'react';
import { FC } from 'react';
import { Button, Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useLocation, useNavigate } from 'react-router-dom';
import { E2ERoutes } from 'src/enums/routes.enums';

interface ApplicationModalProps {
  open: boolean;
  handleContinue: (route: string, continueAASO: any) => void;
  handleStartNew: () => void;
  continueAASO?: any;
  continueRoute: string;
}

const ApplicationModal: FC<ApplicationModalProps> = ({
  open,
  handleContinue,
  handleStartNew,
  continueAASO,
  continueRoute,
}) => {
  const continue_store_name = continueAASO?.store_name;

  const location = useLocation();

  const navigate = useNavigate();

  const handleDashboard = () => navigate('/' + E2ERoutes.Dashboard);

  // const theme = useTheme();

  const hideNewAppButton = location?.pathname === '/' && !location?.search?.length;

  return (
    <Dialog maxWidth={false} sx={{ maxWidth: '750px', margin: 'auto', paddingTop: '40px' }} fullWidth open={open}>
      <DialogTitle variant="h5" sx={{ color: '#243F8F', fontWeight: 'bold' }}>
        {'Continue Application?'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          We noticed you haven't completed your application for {continue_store_name}. Would you like to continue?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ paddingBottom: '40px' }}>
        <Stack
          spacing={3}
          direction={{ xs: 'column', sm: 'row' }}
          sx={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            variant="contained"
            color="warning"
            onClick={() => handleContinue(continueRoute, continueAASO)}
            sx={{
              minWidth: { xs: '225px', sm: 'auto' },
              color: '#243F8F',
              textTransform: 'uppercase',
              '&:hover': {
                backgroundColor: '#dbab47',
              },
            }}
          >
            Continue Application
          </Button>
          {!hideNewAppButton && (
            <Button
              variant="contained"
              color="warning"
              onClick={handleStartNew}
              sx={{
                minWidth: { xs: '225px', sm: 'auto' },
                color: '#243F8F',
                textTransform: 'uppercase',
                '&:hover, &:focus': {
                  backgroundColor: '#dbab47',
                },
              }}
            >
              Start New Application
            </Button>
          )}
          <Button
            variant="contained"
            color="warning"
            onClick={handleDashboard}
            sx={{
              minWidth: { xs: '225px', sm: 'auto' },
              color: '#243F8F',
              textTransform: 'uppercase',
              '&:hover': {
                backgroundColor: '#dbab47',
              },
            }}
          >
            Go to Dashboard
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ApplicationModal;
