import React, { useEffect, useState } from 'react';
import {
  e2eConfigLogic_buildProviderResponseLogicObject,
  ProviderOfferLogicObjectI,
} from 'src/e2e-redesign/business-logic/e2e-configuration-object-logic';
import { GroupedOfferI } from 'src/e2e-redesign/views/LenderResponses/logic/provider-response-logic';
import NewDumbNotOfferCard from 'src/e2e-redesign/views/LenderResponses/new-dumb-not-offer-card';
import { LendersI } from 'src/interfaces/lenders.interfaces';
import { ApplicationSubmissionStateDto, AppSubLenderResponseE } from 'src/interfaces/submissions.interfaces';

interface ResponseCardProps {
  groupedOffer: GroupedOfferI;
}

export default function NewDashboardHandleNotOfferCard(props: ResponseCardProps) {
  const { groupedOffer } = props;
  const details = groupedOffer.notOffers[0];
  const submissionDetails: ApplicationSubmissionStateDto = details.sub_details;
  const [offerLogicObj, setOfferLogicObj] = useState<ProviderOfferLogicObjectI>();
  const [showEcoa, setShowEcoa] = useState<boolean>(false);
  const l: LendersI = details.lender;
  const lenderDisclosures = details.lender_disclosures;

  useEffect(() => {
    const showEcoaStatuses = [AppSubLenderResponseE.rejected, AppSubLenderResponseE.soft_decline];
    if (showEcoaStatuses.includes(submissionDetails.lender_response)) {
      setShowEcoa(true);
    }
  }, [submissionDetails]);

  useEffect(() => {
    if (details) {
      const obj = e2eConfigLogic_buildProviderResponseLogicObject(details);
      if (obj) {
        setOfferLogicObj(obj);
      }
    }
  }, [details]);

  const getLenderLogo = () => {
    if (l.logo_url) {
      // R:TODO E2E P3 - see other note about how to handle lender logos
      // Go based on the lender object logo url
      return {
        src: l.logo_url,
        alt: l.name,
      };
    } else {
      // if no logo exists in lender object, use disclosures
      if (lenderDisclosures && lenderDisclosures?.length > 0) {
        const disc = lenderDisclosures.find((ld) => ld.logo.length > 0);
        if (disc) {
          return {
            src: disc.logo,
            alt: l.name,
          };
        }
      }
    }
    return false;
  };

  if (!offerLogicObj) return null;

  const lenderLogoDetails = getLenderLogo();

  return (
    <NewDumbNotOfferCard
      groupedOffer={groupedOffer}
      submissionDetails={submissionDetails}
      lenderLogoDetails={lenderLogoDetails}
      handleUpdateStatus={async () => {}}
      showUpdateStatus={false}
      showEcoa={showEcoa}
    />
  );
}
