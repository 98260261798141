import React, { FC } from 'react';
import { Box } from '@mui/material';

export interface Logo {
  src: string;
  alt: string;
}

interface LogoGridProps {
  logos: Logo[];
  store_image_url?: string;
}

const LogoGrid: FC<LogoGridProps> = (props: LogoGridProps) => {
  // TODO E2E P1 - remove meineke from hardcoded and add to store
  const { logos } = props;
  const e2eLogoUrl = props.store_image_url;
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 2, // spacing between items
        justifyContent: 'center',
      }}
    >
      {e2eLogoUrl && e2eLogoUrl.length > 0 && (
        <Box
          key={'DELTE_THIS_LATER'}
          sx={{
            flex: '0 0 auto',
            maxWidth: { xs: '50%', md: '25%', lg: '20%' },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '15px',
          }}
        >
          <Box
            component="img"
            src={e2eLogoUrl}
            alt={'mineke_logo'}
            sx={{
              maxHeight: '50px',
              maxWidth: '160px',
              width: 'auto',
              height: 'auto',
              objectFit: 'contain',
            }}
          />
        </Box>
      )}
      {logos.map((logo, index) => (
        <Box
          key={index}
          sx={{
            flex: '0 0 auto',
            maxWidth: { xs: '50%', md: '25%', lg: '20%' },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '15px',
          }}
        >
          <Box
            component="img"
            src={logo.src}
            alt={logo.alt}
            sx={{
              maxHeight: '50px',
              maxWidth: '160px',
              width: 'auto',
              height: 'auto',
              objectFit: 'contain',
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default LogoGrid;
