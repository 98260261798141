import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import toast from 'react-hot-toast';
import {
  e2eConfigLogic_buildProviderResponseLogicObject,
  ProviderOfferLogicObjectI,
} from 'src/e2e-redesign/business-logic/e2e-configuration-object-logic';
import {
  E2eLogicObjectsWithAasoParams,
  e2eLogicUtils_getRoutingLogicObjects,
} from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import SingleLogo from 'src/e2e-redesign/components/SingleLogo';
import ThemedCard from 'src/e2e-redesign/components/ThemedCard';
import {
  e2eLogic_getShowStatusIfExists,
  GroupedOfferI,
} from 'src/e2e-redesign/views/LenderResponses/logic/provider-response-logic';
import { EcoaMessage } from 'src/e2e-redesign/views/LenderResponses/new-lender-response-table';
import { LendersI } from 'src/interfaces/lenders.interfaces';
import {
  ApplicationSubmissionStateDto,
  AppSubLenderResponseE,
  AppSubStatusE,
} from 'src/interfaces/submissions.interfaces';
import { parseHtml } from 'src/utils/general';

interface ResponseCardProps {
  // details: NotOffersI;
  groupedOffer: GroupedOfferI;
  lenderLogoDetails: any;
  submissionDetails: any;
  showUpdateStatus: any;
  showEcoa: any;
  handleUpdateStatus: () => Promise<void>;
}

export default function NewDumbNotOfferCard(props: ResponseCardProps) {
  const theme = useTheme();
  const { groupedOffer, lenderLogoDetails, submissionDetails, showUpdateStatus, showEcoa, handleUpdateStatus } = props;
  const [disableUpdateStatus, setDisableUpdateStatus] = useState<boolean>(false);
  const handleUpdateStatusClick = async () => {
    setDisableUpdateStatus(true);
    await handleUpdateStatus();
    setTimeout(() => {
      setDisableUpdateStatus(false);
    }, 1000 * 5);
  };

  const renderDefinitionItem = (term: string, definition: string | number | undefined) => {
    if (definition === undefined || definition === '') return null;
    return (
      <>
        <Typography component="dt" variant="body1" style={{ color: theme.palette.text.primary }}>
          {term}
        </Typography>
        <Typography component="dd" variant="body1" style={{ color: theme.palette.primary.main }}>
          {definition}
        </Typography>
      </>
    );
  };

  const updateWording = (word: string) => {
    if (word === AppSubLenderResponseE.rejected) {
      return 'Declined';
    }
    if (word === AppSubLenderResponseE.nr) {
      return 'Pending';
    }
    return word;
  };

  const getCorrectDeclinedMessage = () => {
    let message = EcoaMessage;
    if (submissionDetails.lender_declined_message && submissionDetails.lender_declined_message.length > 0) {
      message = submissionDetails.lender_declined_message;
    }
    return (
      <Typography variant="body1" style={{ margin: '20px' }} paragraph>
        {parseHtml(message)}
      </Typography>
    );
  };

  return (
    <ThemedCard sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: '52px' }}>
        {lenderLogoDetails && <SingleLogo image={lenderLogoDetails} />}
      </Box>
      <dl style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '5px', marginBottom: '20px' }}>
        {/* {renderDefinitionItem('Applicant Name', `${form_values?.first_name} ${form_values?.last_name}`)} */}
        {renderDefinitionItem('Response', updateWording(submissionDetails.lender_response))}
      </dl>
      {/* TODO: Add response.message conditionally here */}
      {submissionDetails && submissionDetails?.lender_approved_message && (
        <Typography variant="body1" style={{ margin: '20px' }} paragraph>
          {parseHtml(submissionDetails?.lender_approved_message)}
        </Typography>
      )}
      {submissionDetails && submissionDetails?.lender_pending_message && (
        <Typography variant="body1" style={{ margin: '20px' }} paragraph>
          {parseHtml(submissionDetails?.lender_pending_message)}
        </Typography>
      )}
      {showUpdateStatus && (
        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 'auto' }}>
          <Button
            variant="contained"
            color="success"
            fullWidth
            onClick={handleUpdateStatusClick}
            disabled={disableUpdateStatus}
          >
            Update Status
          </Button>
        </Box>
      )}
      {showEcoa && getCorrectDeclinedMessage()}
    </ThemedCard>
  );
}
