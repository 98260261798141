import React, { useEffect, useState } from 'react';
import { Checkbox, TableCell, TableRow, useMediaQuery, useTheme } from '@mui/material';
import { weight } from 'postcss-minify-font-values/types/lib/keywords';
import {
  e2eConfigLogic_buildProviderResponseLogicObject,
  ProviderOfferLogicObjectI,
  ProviderOffersI,
} from 'src/e2e-redesign/business-logic/e2e-configuration-object-logic';
import { tableCellStyle } from 'src/e2e-redesign/views/LenderResponses/new-lender-response-table';
import {
  ApplicationSubmissionStateDto,
  AppSubLenderResponseE,
  LenderOffersDetailedI,
  LenderOffersI,
  LenderOfferValueTypesE,
} from 'src/interfaces/submissions.interfaces';

interface ResponseCardProps {
  card_details: ProviderOffersI;
  selectedOffer: string;
  onSelect: (fpUniqueId: string) => void;
  isPending?: boolean;
  disableActions?: boolean;
  displayFields: {
    account_number: boolean;
    offer_name: boolean;
    approved_amount: boolean;
    rate: boolean;
    apr: boolean;
    term: boolean;
    monthly_payment: boolean;
    application_id: boolean;
    deferred_interest_period: boolean;
    minimum_spend: boolean;
    promotion_description: boolean;
    down_payment: boolean;
  };
  isSingleOffer?: boolean;
}

export default function NewLenderResponseRow(props: ResponseCardProps) {
  const { card_details, selectedOffer, onSelect, displayFields, isPending, disableActions, isSingleOffer } = props;
  const submissionDetails: ApplicationSubmissionStateDto = card_details.sub_details;
  const [offerLogicObj, setOfferLogicObj] = useState<ProviderOfferLogicObjectI>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isFpDetailed = card_details?.offer_details?.fp_detailed_app;

  useEffect(() => {
    if (card_details) {
      const obj = e2eConfigLogic_buildProviderResponseLogicObject(card_details);
      if (obj) {
        setOfferLogicObj(obj);
      }
    }
  }, [card_details]);

  useEffect(() => {
    if (isSingleOffer) {
      const singleOfferId = submissionDetails.lender_offers?.[0]?.fp_unique_id;
      if (singleOfferId && singleOfferId !== selectedOffer) {
        onSelect(singleOfferId);
      }
    }
  }, [isSingleOffer, submissionDetails.lender_offers, onSelect, selectedOffer]);

  if (!offerLogicObj) return null;

  const updateWording = (word: string) => {
    if (word === AppSubLenderResponseE.rejected) {
      return 'Declined';
    }
    if (word === AppSubLenderResponseE.nr) {
      return 'Pending';
    }
    return word;
  };

  const formatCurrency = (value: number | string | undefined) => {
    if (!value) return 'N/A';

    // Convert string to number, removing $ and commas
    const numValue = typeof value === 'string' ? Number(value.replace(/[$,]/g, '')) : value;

    return isNaN(numValue)
      ? 'N/A'
      : `$${numValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  };

  const renderMobileRow = (label: string, value: React.ReactNode) => {
    let styleLabel = {
      width: '1%',
      justifyContent: 'center',
      fontWeight: 'initial',
      fontSize: '1rem',
    };
    const styleValue = {
      width: '1%',
      justifyContent: 'center',
      fontWeight: 'bold',
      fontSize: '1rem',
    };
    if (label.includes('Offer') && label !== 'Offer Name') {
      styleLabel = {
        ...styleLabel,
        fontWeight: 'bold',
        fontSize: '1.2rem',
      };
    }

    return (
      <TableRow>
        <TableCell sx={styleLabel} component="th" scope="row">
          {label.toUpperCase()}
        </TableCell>
        <TableCell sx={styleValue} align="right">
          {value}
        </TableCell>
      </TableRow>
    );
  };

  const handleFpDetailedField = (field: LenderOffersDetailedI) => {
    switch (field.type) {
      case LenderOfferValueTypesE.currency:
        // need to get this working
        return formatCurrency(field.value);
      case LenderOfferValueTypesE.percentage:
        return `${field.value}%`;
      case LenderOfferValueTypesE.url:
        return (
          <a href={field.value} target="_blank" rel="noreferrer">
            {field.value}
          </a>
        );
      default:
        return field.value;
    }
  };
  const handleShowRow = (lo: LenderOffersI) => {
    const fpa = lo?.fp_detailed_app;
    if (isFpDetailed && fpa) {
      return (
        <>
          {fpa.map((field: LenderOffersDetailedI) => {
            if (field.show) {
              return (
                <TableCell align="center" style={tableCellStyle}>
                  {field?.value ? handleFpDetailedField(field) : 'N/A'}
                </TableCell>
              );
            }
          })}
        </>
      );
    } else {
      return (
        <>
          {displayFields.account_number && (
            <TableCell align="center" style={tableCellStyle}>
              {lo?.account_number || 'N/A'}
            </TableCell>
          )}
          <TableCell align="center" style={tableCellStyle}>
            {updateWording(submissionDetails.lender_response)}
          </TableCell>
          {displayFields.approved_amount && (
            <TableCell align="center" style={tableCellStyle}>
              {formatCurrency(Number(lo?.approved_amount))}
            </TableCell>
          )}
          {displayFields.offer_name && (
            <TableCell align="center" style={tableCellStyle}>
              {lo?.offer_name || 'N/A'}
            </TableCell>
          )}
          {displayFields.rate && (
            <TableCell align="center" style={tableCellStyle}>
              {lo?.rate || 'N/A'}
            </TableCell>
          )}
          {displayFields.apr && (
            <TableCell align="center" style={tableCellStyle}>
              {lo?.apr ? `${lo.apr}%` : 'N/A'}
            </TableCell>
          )}
          {displayFields.term && (
            <TableCell align="center" style={tableCellStyle}>
              {lo?.term || 'N/A'}
            </TableCell>
          )}
          {displayFields.monthly_payment && (
            <TableCell align="center" style={tableCellStyle}>
              {formatCurrency(lo?.monthly_payment)}
            </TableCell>
          )}
          {displayFields.application_id && (
            <TableCell align="center" style={tableCellStyle}>
              {lo?.application_id || 'N/A'}
            </TableCell>
          )}
          {displayFields.deferred_interest_period && (
            <TableCell align="center" style={tableCellStyle}>
              {lo?.deferred_interest_period || 'N/A'}
            </TableCell>
          )}
          {displayFields.minimum_spend && (
            <TableCell align="center" style={tableCellStyle}>
              {lo?.minimum_spend || 'N/A'}
            </TableCell>
          )}
          {displayFields.promotion_description && (
            <TableCell align="center" style={tableCellStyle}>
              {lo?.promotion_description || 'N/A'}
            </TableCell>
          )}
          {displayFields.down_payment && (
            <TableCell align="center" style={tableCellStyle}>
              {lo?.down_payment || 'N/A'}
            </TableCell>
          )}
        </>
      );
    }
  };

  const handleShowMobile = (lo: LenderOffersI, isSelected: boolean, index: number) => {
    const fpa = lo?.fp_detailed_app;
    if (isFpDetailed && fpa) {
      return (
        <React.Fragment key={lo.fp_unique_id}>
          {!isPending &&
            !disableActions &&
            !isSingleOffer &&
            renderMobileRow(
              `Offer ${index + 1}`,
              <Checkbox
                checked={isSelected}
                onChange={() => onSelect(lo.fp_unique_id as string)}
                inputProps={{ 'aria-label': 'select offer' }}
              />,
            )}
          {fpa.map((field: LenderOffersDetailedI) => {
            if (field.show) {
              return renderMobileRow(field.label, field?.value ? handleFpDetailedField(field) : 'N/A');
            }
          })}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment key={lo.fp_unique_id}>
          {!isPending &&
            !disableActions &&
            !isSingleOffer &&
            renderMobileRow(
              `Offer ${index + 1}`,
              <Checkbox
                checked={isSelected}
                onChange={() => onSelect(lo.fp_unique_id as string)}
                inputProps={{ 'aria-label': 'select offer' }}
              />,
            )}

          {renderMobileRow('Response', updateWording(submissionDetails.lender_response))}
          {lo.offer_name && renderMobileRow('Offer Name', lo?.offer_name || 'N/A')}
          {displayFields.account_number && renderMobileRow('Account Number', lo?.account_number || 'N/A')}
          {displayFields.approved_amount &&
            renderMobileRow('Approved Amount', formatCurrency(Number(lo?.approved_amount)))}
          {displayFields.rate && renderMobileRow('Rate', lo?.rate || 'N/A')}
          {displayFields.apr && renderMobileRow('APR', lo?.apr ? `${lo.apr}%` : 'N/A')}
          {displayFields.term && renderMobileRow('Term', lo?.term || 'N/A')}
          {displayFields.monthly_payment && renderMobileRow('Monthly Payment', formatCurrency(lo?.monthly_payment))}
          {displayFields.application_id && renderMobileRow('Application ID', lo?.application_id || 'N/A')}
          {displayFields.deferred_interest_period &&
            renderMobileRow('Deferred Interest Period', lo?.deferred_interest_period || 'N/A')}
          {displayFields.minimum_spend && renderMobileRow('Minimum Spend', lo?.minimum_spend || 'N/A')}
          {displayFields.promotion_description &&
            renderMobileRow('Promotion Description', lo?.promotion_description || 'N/A')}
          {displayFields.down_payment && renderMobileRow('Down Payment', lo?.down_payment || 'N/A')}
        </React.Fragment>
      );
    }
  };
  return (
    submissionDetails.lender_offers &&
    submissionDetails.lender_offers.map((lenderOffer, index) => {
      const isSelected = selectedOffer === lenderOffer?.fp_unique_id;

      if (isMobile) {
        return handleShowMobile(lenderOffer, isSelected, index);
      }

      return (
        <TableRow key={lenderOffer.fp_unique_id}>
          {!isPending && !disableActions && !isSingleOffer && (
            <TableCell align="center" style={tableCellStyle}>
              <Checkbox
                checked={isSelected}
                onChange={() => onSelect(lenderOffer.fp_unique_id as string)}
                inputProps={{ 'aria-label': 'select offer' }}
              />
            </TableCell>
          )}
          {handleShowRow(lenderOffer)}
        </TableRow>
      );
    })
  );
}
