import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Unstable_Grid2 as Grid, Typography } from '@mui/material';
import { Seo } from 'src/components/seo';
import SingleLogo from 'src/e2e-redesign/components/SingleLogo';
import ThemedCard from 'src/e2e-redesign/components/ThemedCard';
import ThemedContainer from 'src/e2e-redesign/components/ThemedContainer';
import { DashboardLenderLogo } from 'src/e2e-redesign/e2e-dashboard/views/dashboard.interface';
import E2eDashboardResponseHandler from 'src/e2e-redesign/e2e-dashboard/views/e2e-dashboard-response-handler';
import {
  DashboardAppsI,
  dashboardLogic_funcAndV,
} from 'src/e2e-redesign/e2e-dashboard/views/logic/e2e-client-index.logic';
import AutoApprovalCardTable from 'src/e2e-redesign/views/LenderResponses/auto-approval-card-table';
import { GroupedOfferI } from 'src/e2e-redesign/views/LenderResponses/logic/provider-response-logic';
import NewDashboardHandleNotOfferCard from 'src/e2e-redesign/views/LenderResponses/new-dashboard-handle-not-offer-card';
import { AASO } from 'src/interfaces/aaso.interfaces';
import { ApplicationSubmissionStateDto, LenderOffersI } from 'src/interfaces/submissions.interfaces';
import type { Page as PageType } from 'src/types/page';

import DashboardIntro from '../components/dashboard-intro';
import OfferDefinitionItem from '../components/definition-list';

export const getNestedOfferDate = (app: any): string => {
  const timeCompleted = app.created_at;
  if (!timeCompleted) return 'Date Not Available'; // Handle undefined or null
  const date = new Date(timeCompleted);
  if (isNaN(date.getTime())) return 'Date Not Available'; // Handle invalid date
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

const E2eClientIndex: PageType = () => {
  const { v } = dashboardLogic_funcAndV();
  const { apps } = v;

  const CheckmarkSVG = () => (
    <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" fill="#4CAF50" />
    </svg>
  );

  const getProperCard = (groupedOffer: GroupedOfferI, index: number) => {
    if (groupedOffer.is_offer) {
      return <E2eDashboardResponseHandler groupedOffer={groupedOffer} index={index} />;
    }
    // TODO FIX - NewNotOfferCard broken bc it's using the routing package
    if (groupedOffer.is_offer === false && !groupedOffer.is_auto) {
      return <NewDashboardHandleNotOfferCard groupedOffer={groupedOffer} />;
    }
    if (groupedOffer.is_auto) {
      return <AutoApprovalCardTable groupedOffer={groupedOffer} index={index} />;
    }
  };

  const getOffersMessage = (a: DashboardAppsI): string => {
    const offers = a.offers;
    const autoApprovals = a.autoApprovals;
    if (offers.length > 0 || autoApprovals.length > 0) {
      if (offers.length > 1 || autoApprovals.length > 1) {
        return `${offers.length + autoApprovals.length} Offers`;
      } else if (autoApprovals.length > 0) {
        return `${offers.length + autoApprovals.length} Offer`;
      } else {
        return `${offers.length + autoApprovals.length} Offer`;
      }
    }
    return 'No Offers';
  };

  const handleRedirectOrOffers = () => {
    if (apps.length > 0) {
      return (
        <Box>
          {apps.map((app: DashboardAppsI, appIndex) => (
            <Accordion key={appIndex} sx={{ boxShadow: 'none', border: 'none', marginBottom: 2 }}>
              <ThemedCard sx={{ width: '100%' }}>
                <AccordionSummary aria-controls={`panel${appIndex}-content`} id={`panel${appIndex}-header`}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      gap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <ExpandMoreIcon sx={{ marginRight: 1 }} />
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: { xs: 'column', sm: 'row' },
                          flexGrow: 1,
                          justifyContent: { sm: 'space-between' },
                          alignItems: { xs: 'flex-start', sm: 'center' },
                          gap: 1,
                        }}
                      >
                        <Typography variant="h6" component="h2" color="primary">
                          {app.store.store_name}
                        </Typography>
                        <Typography variant="h6" component="p" color="primary">
                          {getOffersMessage(app)}
                        </Typography>
                        <Typography variant="h6" component="p" color="primary">
                          {getNestedOfferDate(app)}
                        </Typography>
                      </Box>
                    </Box>

                    <Typography variant="body1" component="p" color="primary" style={{ paddingLeft: 30 }}>
                      To finalize your transaction or for more assistance, contact your store associate.
                    </Typography>
                  </Box>
                </AccordionSummary>
              </ThemedCard>

              <AccordionDetails>
                <Grid container spacing={2}>
                  {app.combinedResponses.map((groupedOffer: GroupedOfferI, index) => (
                    <Grid xs={12} key={index}>
                      {getProperCard(groupedOffer, index)}
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      );
    }
  };

  const renderOfferCard = (
    offer: LenderOffersI,
    submission: ApplicationSubmissionStateDto,
    lenderLogo: DashboardLenderLogo[],
    aaso: AASO,
  ) => {
    const isSelectedOffer = aaso?.user_selection?.selections?.find((selection) => {
      if (selection.fp_unique_id === offer.fp_unique_id) return true;
      return false;
    });
    const logo = lenderLogo.find((logo) => {
      if (logo.lender_id?.toString() == submission.lender_id?.toString()) {
        return logo;
      }
    })?.logo;
    return (
      <Grid key={offer?.fp_unique_id} xs={12} sm={6} md={4}>
        <Box
          sx={{
            marginBottom: '20px',
            border: '1px solid #E0E8F5',
            borderRadius: '15px',
            minHeight: '100%',
            padding: '15px',
            overflow: 'hidden',
          }}
        >
          <Grid container spacing={2}>
            <Grid xs={6}>
              <SingleLogo image={{ src: logo || '/logo_full.png', alt: 'logo' }} />
            </Grid>
            <Grid xs={6} display="flex" justifyContent="flex-end">
              {isSelectedOffer && <CheckmarkSVG />}
            </Grid>
          </Grid>
          <dl style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '8px' }}>
            {submission.lender_response &&
              OfferDefinitionItem({
                term: 'Response',
                definition: submission.lender_response?.toUpperCase(),
              })}
            {offer?.approved_amount &&
              OfferDefinitionItem({
                term: 'Approved Amount',
                definition: `$${offer?.approved_amount}`,
              })}
          </dl>
        </Box>
      </Grid>
    );
  };

  return (
    <>
      <Seo title="Dashboard" />
      <ThemedContainer>
        <DashboardIntro />

        <Grid container spacing={2}>
          {handleRedirectOrOffers()}
        </Grid>
      </ThemedContainer>
    </>
  );
};

export default E2eClientIndex;
