import React, { useRef } from 'react';
import { Input as BaseInput } from '@mui/base/Input';
import { Box, Button, styled } from '@mui/material';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';

const digitFields = Array.from({ length: 6 }, (_, i) => `digit${i + 1}`);

const initialValues = digitFields.reduce(
  (acc: Record<string, string>, field: string) => {
    acc[field] = '';
    return acc;
  },
  { otpError: '' },
);

const validationSchema = Yup.object(
  digitFields.reduce((acc: Record<string, Yup.StringSchema>, field: string) => {
    acc[field] = Yup.string()
      .matches(/^[0-9]$/, 'Numbers only')
      .required('Required');
    return acc;
  }, {}),
).shape({
  otpError: Yup.string().test('otp-error', '', function (value, context) {
    const otp = digitFields.map((field) => context.parent[field]).join('');
    return otp.length === 6 && /^[0-9]{6}$/.test(otp) ? true : this.createError({ message: 'Invalid OTP code' });
  }),
});

interface ValuesType {
  [key: string]: string;
  otpError: string;
}

const handleKeyDown = (
  e: React.KeyboardEvent<HTMLInputElement>,
  index: number,
  values: ValuesType,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  nextFocus: React.MutableRefObject<number>,
) => {
  if (e.key === 'Backspace' && values[`digit${index + 1}`] === '') {
    if (index > 0) {
      document.getElementById(`code-input-${index - 1}`)?.focus();
    }
  } else if (/^[0-9]$/.test(e.key)) {
    setFieldValue(`digit${index + 1}`, e.key);
    if (index < digitFields.length - 1) {
      nextFocus.current = index + 1;
      document.getElementById(`code-input-${index + 1}`)?.focus();
    }
    e.preventDefault();
  }
};

const InputElement = styled('input')`
  width: 40px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 0px;
  border-radius: 8px;
  text-align: center;
  color: #1c2025;
  background: #fff;
  border: 1px solid #dae2ed;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);

  &:hover {
    border-color: #3399ff;
  }

  &:focus {
    border-color: #3399ff;
    box-shadow: 0 0 0 3px #80bfff;
  }

  &:focus-visible {
    outline: 0;
  }
`;

interface OtpProps {
  onSubmit: (values: string) => Promise<any>;
  sendOtp: (bypass?: boolean) => void;
}

export default function OtpVerification({ onSubmit, sendOtp }: OtpProps) {
  const handleResubmit = async () => {
    await sendOtp(true);
  };
  return (
    <Formik
      initialValues={initialValues as ValuesType}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        const otp = digitFields.map((field) => values[field]).join('');
        setSubmitting(true);
        if (!/^[0-9]{6}$/.test(otp)) {
          setFieldError('otpError', 'Invalid Verification code');
          setSubmitting(false);
        } else {
          await onSubmit(otp);
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, values, setFieldValue }) => {
        const nextFocus = useRef(0);
        const inputRefs = useRef<HTMLInputElement[]>(new Array(digitFields.length).fill(null));

        const focusInput = (targetIndex: number) => {
          const targetInput = inputRefs.current[targetIndex];
          targetInput?.focus();
        };

        const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>, currentIndex: number) => {
          e.preventDefault();
          const clipboardData = e.clipboardData.getData('Text').slice(0, digitFields.length);
          clipboardData.split('').forEach((char, idx) => {
            if (/^[0-9]$/.test(char)) {
              setFieldValue(digitFields[currentIndex + idx], char);
            }
          });
          if (currentIndex + clipboardData.length < digitFields.length) {
            focusInput(currentIndex + clipboardData.length);
          }
        };

        return (
          <Form>
            <Box display="flex" flexDirection="column" alignItems={{ xs: 'center', md: 'flex-start' }} gap={2} mb={3}>
              <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
                {digitFields.map((name, index) => (
                  <BaseInput
                    key={index}
                    slots={{
                      input: InputElement,
                    }}
                    id={`code-input-${index}`}
                    aria-label={`Digit ${index + 1} of OTP`}
                    slotProps={{
                      input: {
                        ref: (ele: HTMLInputElement) => {
                          inputRefs.current[index] = ele!;
                        },
                        inputMode: 'numeric', // Add this line
                        pattern: '[0-9]*', // Add this line
                        onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) =>
                          handleKeyDown(e, index, values, setFieldValue, nextFocus),
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          const currentValue = e.target.value;
                          let indexToEnter = 0;

                          while (indexToEnter <= index) {
                            if (inputRefs.current[indexToEnter].value && indexToEnter < index) {
                              indexToEnter += 1;
                            } else {
                              break;
                            }
                          }
                          setFieldValue(`digit${index + 1}`, currentValue);
                          if (currentValue !== '' && index < digitFields.length - 1) {
                            nextFocus.current = index + 1;
                            focusInput(index + 1);
                          }
                        },
                        onPaste: (e: React.ClipboardEvent<HTMLInputElement>) => handlePaste(e, index),
                        value: values[name] ?? '',
                      },
                    }}
                  />
                ))}
              </Box>
              <ErrorMessage name="otpError">
                {(msg) => (
                  <Box color="error.main" mt={1}>
                    {msg}
                  </Box>
                )}
              </ErrorMessage>
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  flexDirection: { xs: 'column', md: 'row' }, // Stack vertically on mobile
                  alignItems: { xs: 'stretch', md: 'center' }, // Stretch buttons on mobile
                  marginTop: { xs: '20px', md: '30px' },
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit" // This one submits the form
                  disabled={isSubmitting}
                >
                  Verify
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={handleResubmit} // This one just triggers the sendOtp function
                  disabled={isSubmitting}
                >
                  Resend Verification Code
                </Button>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
}
