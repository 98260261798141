export enum ApiMethodTypes {
  'POST' = 'POST',
  'GET' = 'GET',
  'PUT' = 'PUT',
  'PATCH' = 'PATCH',
  'DELETE' = 'DELETE',
}

export interface RequestObjectI {
  url: string;
  method: ApiMethodTypes;
  header?: object;
}

export const API_LOGIN: RequestObjectI = {
  url: 'auth/login/',
  method: ApiMethodTypes.POST,
};
export const API_REGISTER: RequestObjectI = {
  url: 'auth/register/',
  method: ApiMethodTypes.POST,
};

export const API_SIGN_OUT: RequestObjectI = {
  url: 'auth/sign-out/',
  method: ApiMethodTypes.POST,
};

export const API_VALIDATE_USER: RequestObjectI = {
  url: 'auth/validate/',
  method: ApiMethodTypes.GET,
};

export const API_VALIDATE_APPROVAL: RequestObjectI = {
  url: 'e2e/validate-approval/',
  method: ApiMethodTypes.GET,
};

export const API_VALIDATE_OTP: RequestObjectI = {
  url: 'auth/validate-otp/',
  method: ApiMethodTypes.POST,
};

export const API_GET_DASHBOARD_DATA: RequestObjectI = {
  url: 'dashboard',
  method: ApiMethodTypes.GET,
};

export const API_GET_DASHBOARD_FORMS: RequestObjectI = {
  url: 'dashboard/forms',
  method: ApiMethodTypes.GET,
};

export const API_GET_DASHBOARD_OPEN_OFFERS: RequestObjectI = {
  url: 'dashboard/open-offers',
  method: ApiMethodTypes.GET,
};

export const API_GET_DASHBOARD_APPLICATIONS: RequestObjectI = {
  url: 'dashboard/applications',
  method: ApiMethodTypes.GET,
};

export const API_GET_DASHBOARD_ACCEPTED_OFFERS: RequestObjectI = {
  url: 'dashboard/accepted-offers',
  method: ApiMethodTypes.GET,
};

export const API_GET_AASO: RequestObjectI = {
  url: 'aaso/:id',
  method: ApiMethodTypes.GET,
};

export const API_GET_AASO_BY_APPLICANT: RequestObjectI = {
  url: 'aaso/applicant/:applicant_id/:store_uuid',
  method: ApiMethodTypes.GET,
};

export const API_POST_CREATE_AASO: RequestObjectI = {
  url: 'aaso/:applicant_id/:store_uuid/create',
  method: ApiMethodTypes.POST,
};

export const API_GET_STORE_LENDER_DISCLOSURES: RequestObjectI = {
  url: 'stores/disclosures/:store_id',
  method: ApiMethodTypes.GET,
};

// R:TODO E2E P3 Consolidate GET_STORE_LENDER_DISCLOSURES_WITH_STORE_UUID and GET_STORE_LENDER_DISCLOSURES
export const API_GET_STORE_LENDER_DISCLOSURES_WITH_STORE_UUID: RequestObjectI = {
  url: 'stores/disclosures/store_uuid/:store_uuid',
  method: ApiMethodTypes.GET,
};

export const API_POST_AASO: RequestObjectI = {
  url: 'aaso',
  method: ApiMethodTypes.POST,
};

// R:TODO E2E P1 - REMOVE
export const API_GET_PRIME_LENDER: RequestObjectI = {
  url: 'lenders/prime-lenders',
  method: ApiMethodTypes.GET,
};

// R:TODO E2E - REMOVE
export const API_GET_SUB_PRIME_LENDER: RequestObjectI = {
  url: 'lenders/sub-prime-lenders',
  method: ApiMethodTypes.GET,
};

export const API_GET_STORE: RequestObjectI = {
  url: 'stores/:id',
  method: ApiMethodTypes.GET,
};

export const API_ENROLLMENT_CHECK_APPLICANT: RequestObjectI = {
  url: 'enrollment/check-applicant',
  method: ApiMethodTypes.POST,
};

export const API_ENROLLMENT_VERIFY_ID: RequestObjectI = {
  url: 'enrollment/verify-id',
  method: ApiMethodTypes.POST,
};

export const API_ENROLL_RESEND_VERIFICATION_CODE: RequestObjectI = {
  url: 'enrollment/resend-verification',
  method: ApiMethodTypes.POST,
};
export const API_ENROLLMENT_VERIFY_ADDRESS: RequestObjectI = {
  url: 'enrollment/verify-address',
  method: ApiMethodTypes.POST,
};

export const API_ENROLLMENT_CHECK_ENROLEE: RequestObjectI = {
  url: 'enrollment/check-enrolee',
  method: ApiMethodTypes.GET,
};

export const API_ENROLLMENT_GET_ADDRESS_LIST: RequestObjectI = {
  url: 'enrollment/address-list',
  method: ApiMethodTypes.GET,
};

export const API_ENROLLMENT_CREATE_PASSWORD_AND_COMPLETE: RequestObjectI = {
  url: 'enrollment/create-password',
  method: ApiMethodTypes.POST,
};

export const API_PATCH_AUTH_CHANGE_PASSWORD: RequestObjectI = {
  url: 'auth/change-password',
  method: ApiMethodTypes.PATCH,
};

export const API_PATCH_AUTH_SET_INITIAL_PASSWORD: RequestObjectI = {
  url: 'auth/set-initial-password',
  method: ApiMethodTypes.PATCH,
};

export const API_PATCH_OPT_OUT_DATA: RequestObjectI = {
  url: 'applicants/update-opt-out',
  method: ApiMethodTypes.PATCH,
};

export const API_GET_APPLICANT_OPT_OUT: RequestObjectI = {
  url: 'applicants/applicant-opt-out',
  method: ApiMethodTypes.GET,
};

export const API_GET_E2E_CONSUMER_FACING_FORM_DETAILS: RequestObjectI = {
  url: 'application-settings/e2e-form-details/:store_uuid',
  method: ApiMethodTypes.GET,
};

export const API_POST_SAVE_APPLICATION_RESULTS: RequestObjectI = {
  url: 'e2e/:form_id/:aaso_id/application-results',
  method: ApiMethodTypes.POST,
};

export const API_POST_SAVE_DISCLOSURE_SIGNATURES: RequestObjectI = {
  url: 'aaso/:aaso_id/save-disclosure-signatures',
  method: ApiMethodTypes.POST,
};

export const API_GET_E2E_FORM: RequestObjectI = {
  url: 'forms/:aaso_id/get-forms',
  method: ApiMethodTypes.GET,
};

export const API_GET_E2E_FORM_SUBMISSIONS: RequestObjectI = {
  url: 'e2e/:aaso_id/submissions',
  method: ApiMethodTypes.GET,
};

export const API_GET_E2E_KICKOFF: RequestObjectI = {
  url: 'e2e/:aaso_id/kickoff',
  method: ApiMethodTypes.GET,
};

export const API_POST_FORGOT_PASSWORD: RequestObjectI = {
  url: 'auth/forgot-password',
  method: ApiMethodTypes.POST,
};

export const API_POST_VALIDATE_FORGOT_PASSWORD_TOKEN: RequestObjectI = {
  url: 'auth/forgot-password/validate-token',
  method: ApiMethodTypes.POST,
};

export const API_POST_RESET_PASSWORD: RequestObjectI = {
  url: 'auth/reset-password',
  method: ApiMethodTypes.POST,
};

export const API_GET_LOCATIONS: RequestObjectI = {
  url: 'locations',
  method: ApiMethodTypes.GET,
};

export const API_POST_CONTACT_FORMPIPER: RequestObjectI = {
  url: 'auth/contact-formpiper',
  method: ApiMethodTypes.POST,
};
