import { useEffect, useState } from 'react';
import { E2eBaseCallDetailsI, e2eGetBaseAasoRequests } from 'src/api/query-logic/e2e-query-base-calls';
import { e2eLogic_setPathIndex } from 'src/e2e-redesign/business-logic/e2e-business-logic';
import { e2eConfigLogic_buildStoreLenderLogoObjects } from 'src/e2e-redesign/business-logic/e2e-configuration-object-logic';
import { E2eLogicObjectsWithAasoParams } from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import { E2eRoutingArgsI, handleE2eRoutingLogic } from 'src/e2e-redesign/business-logic/e2e-routing-logic';
import { Logo } from 'src/e2e-redesign/components/LogoGrid';
import { ComponentLogicI } from 'src/e2e-redesign/interfaces/e2e-base-interfaces';
import useE2eStore from 'src/e2e-store';
import { ApplicationStage } from 'src/enums/aaso.enums';
import { NewE2ERoutes } from 'src/enums/routes.enums';
import { E2eAppSettingsFormPathsI } from 'src/interfaces/application-settings.interfaces';
import { E2eGlobalStore } from 'src/interfaces/e2e-state.interfaces';
import { fsTrackEvent } from 'src/utils/fullstory';

export const getStartedLogic_init = (routingPackage: E2eLogicObjectsWithAasoParams) => {
  const params = routingPackage.params;
  const { store_uuid, e2e_form_id, aaso_id } = params;
  const { e2eStore } = routingPackage;
  const app_configs = e2eStore.app_configurations;
  const aaso = e2eStore.aaso;
  const updateAaso = useE2eStore((store: E2eGlobalStore) => store.updateAASO);
  const baseCallDetails: E2eBaseCallDetailsI = {
    store_uuid,
    e2e_form_id,
    aaso_id,
  };

  const { aasoIsFetched } = e2eGetBaseAasoRequests(baseCallDetails, e2eStore, true);
  useEffect(() => {
    /**
     * NOTE E2E
     * - if the user gets to this page and there is NOT a path_index, then we need to update the AASO object
     */
    if (e2eStore.application_settings && aasoIsFetched) {
      e2eLogic_setPathIndex(e2eStore, updateAaso, e2e_form_id);
    }
  }, [e2eStore.application_settings, e2eStore.aaso, aasoIsFetched]);

  useEffect(() => {
    if (app_configs && (aasoIsFetched || aaso) && !aaso.current_path_id) {
      // TODO E2E P1 - need to test before launching
      const currentForm = app_configs?.current_form;
      const isOnlyPath = currentForm?.paths.length === 1;
      if (isOnlyPath) {
        const onlyPath = currentForm?.paths[0];
        if (currentForm && onlyPath) {
          updateAaso({ data: { current_path_id: onlyPath.id }, sync: true });
        }
      }
    }
  }, [aasoIsFetched, app_configs, aaso]);
};

export interface GetStartedLogicI extends ComponentLogicI {
  fn: {
    handleRouting: () => void;
    handleGoBack: () => void;
    toggleDrawer: () => void;
  };
  v: {
    multi_paths_exist?: boolean;
    disclosureLogos: Logo[];
    drawerState: boolean;
    e2ePath?: E2eAppSettingsFormPathsI;
  };
}
export const getStartedLogic_functionsAndVals = (routingPackage: E2eLogicObjectsWithAasoParams): GetStartedLogicI => {
  const { navigate, e2eStore, params } = routingPackage;
  const [disclosureLogos, setDisclosureLogos] = useState<Logo[]>([]);
  const { e2e_form_id } = params;
  const app_configs = e2eStore.app_configurations;
  const aaso = e2eStore.aaso;
  const [multi_paths_exist, setMultiPathsExist] = useState();
  const e2eForm = e2eStore.application_settings?.e2e?.e2e_forms.find((form) => form.id === e2e_form_id);
  const [e2ePath, setE2ePath] = useState<E2eAppSettingsFormPathsI | undefined>();
  const [drawerState, setDrawerState] = useState<boolean>(false);
  const [fsAddStoreDetails, setFsAddStoreDetails] = useState<boolean>(false);
  const toggleDrawer = () => {
    setDrawerState(!drawerState);
  };
  useEffect(() => {
    if (!fsAddStoreDetails && app_configs?.store_details) {
      console.log('FS track even - FormStarted');
      const storeDetails = app_configs.store_details;
      const fsProps = {
        store_id: storeDetails.id,
        store_name: storeDetails.store_name,
        franchise: storeDetails?.franchise?.name,
        industry: storeDetails?.industry?.name,
        laa: storeDetails?.laa?.name,
        location: storeDetails?.location?.state_name,
        org_id: storeDetails?.org_id,
      };
      fsTrackEvent('CFA Form Started', fsProps);
      setFsAddStoreDetails(true);
    }
  }, [fsAddStoreDetails, app_configs?.store_details]);
  useEffect(() => {
    const newPaths: E2eAppSettingsFormPathsI[] = [];
    if (e2eForm && e2eForm?.paths?.length > 0 && aaso) {
      const currentPathId = aaso?.current_path_id;
      const paths = e2eForm.paths;
      const foundPath = paths.find((p) => currentPathId === p.id);
      if (foundPath) {
        setE2ePath(foundPath);
      }
    }
  }, [e2eForm, aaso]);
  // const multi_paths_exist = app_configs?.multi_paths_exist;

  useEffect(() => {
    setMultiPathsExist(multi_paths_exist);
  }, [app_configs]);

  useEffect(() => {
    if (app_configs?.current_path?.lenders) {
      const dls = e2eConfigLogic_buildStoreLenderLogoObjects(app_configs, e2eStore.disclosures);
      setDisclosureLogos(dls);
    }
  }, [app_configs, e2eStore.disclosures]);

  const handleRouting = () => {
    const routingArgs: E2eRoutingArgsI = {
      currentStage: ApplicationStage.FLOW_STARTED,
      currentE2eRoute: NewE2ERoutes.GetStarted,
    };
    handleE2eRoutingLogic(routingPackage, routingArgs);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return {
    fn: {
      handleRouting,
      handleGoBack,
      toggleDrawer,
    },
    v: {
      multi_paths_exist,
      disclosureLogos,
      drawerState,
      e2ePath,
    },
  };
};
