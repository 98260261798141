import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';

// Create a styled Typography component
const DisclosureHeading = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 700,
  fontSize: '1.7rem !important',
  color: '#243F8F',
  lineHeight: '2.5rem',
  marginBottom: theme.spacing(2), // Use theme spacing for consistency
}));

// Set default props if needed
DisclosureHeading.defaultProps = {
  component: 'h2', // Render as an h2 element by default
};

export default DisclosureHeading;