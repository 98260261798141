import { FullStory, init } from '@fullstory/browser';

export const fsStart = (user: any) => {
  if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
    console.log('init full story');
    // init full story once user has logged in
    init({ orgId: 'o-2288PY-na1' });
    FullStory('setIdentity', {
      uid: user.id,
      properties: {
        applicantId: user.id,
        applicant_name: `${user.first_name} ${user.last_name}`,
        email: `${user.email}`,
        env: process.env.REACT_APP_ENVIRONMENT,
        fp_app: 'applicant-client',
      },
    });
    // FullStory('trackEvent', {
    //   name: 'Applicant Logged In',
    //   properties: {
    //     applicantId: user.id,
    //     applicant_name: `${user.first_name} ${user.last_name}`,
    //     email: `${user.email}`,
    //   },
    // });
  }
};

export const fsTrackEvent = (name: string, props: any) => {
  if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
    FullStory('trackEvent', {
      name,
      properties: props,
    });
  }
};
