import { ApiError } from 'src/utils/error';

import { getBaseApiUrl, getBaseUrl } from '../utils/axios';
import { RequestObjectI } from './request-objects';

export interface RequestDataI {
  body?: object;
  query?: object;
  params?: object;
}

interface BaseRequestI {
  url: string;
  args: RequestInit;
}

const buildQuery = (query: object): string =>
  Object.keys(query)
    // @ts-ignore
    .map((key) => key + '=' + query[key])
    .join('&');

const replaceWildCards = (path: string, params: object) => {
  const wildCardKeys = Object.keys(params);
  for (const wKey of wildCardKeys) {
    const regex = new RegExp(':' + wKey);
    // @ts-ignore
    path = path.replace(regex, params[wKey]);
  }
  return path;
};

const buildPath = (req: RequestObjectI, data?: RequestDataI): string => {
  let path = req.url;
  if (data) {
    const { params, query } = data;
    if (params) {
      if (path.includes(':')) {
        path = replaceWildCards(path, params);
      }
    }
    if (query) {
      path += `?${buildQuery(query)}`;
    }
  }
  return path;
};

const baseRequest = (requestObject: RequestObjectI, data?: RequestDataI): BaseRequestI => {
  const url = buildPath(requestObject, data);
  return {
    url: `${getBaseApiUrl()}/${url}`,
    args: {
      method: requestObject.method,
      body: data?.body ? JSON.stringify(data.body) : undefined,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': getBaseUrl(),
      },
    },
  };
};

export const apiRequest = async (requestObject: RequestObjectI, data?: RequestDataI) => {
  try {
    const { url, args } = baseRequest(requestObject, data);
    const response = await fetch(url, args);
    if (response) {
      const body = await parseJson(response);
      if (response.ok) {
        return body;
      } else {
        const error: ApiError = new Error('API_ERROR') as ApiError;
        error.statusCode = body.statusCode;
        if (!url.includes('/auth/validate')) {
          // they session has expired
          if (body.statusCode === 401) {
            window.location.reload();
          }
        }

        error.statusMessage = body.message;
        error.body = body;
        throw error;
      }
    }
  } catch (e) {
    throw e;
  }
};

export const apiRequestNoRedirect = async (requestObject: RequestObjectI, data?: RequestDataI) => {
  try {
    const { url, args } = baseRequest(requestObject, data);
    const response = await fetch(url, args);
    if (response) {
      const body = await parseJson(response);
      if (response.ok) {
        return body;
      } else {
        const error: ApiError = new Error('API_ERROR') as ApiError;
        error.statusCode = body.statusCode;

        error.statusMessage = body.message;
        error.body = body;
        throw error;
      }
    }
  } catch (e) {
    throw e;
  }
};

const parseJson = async (response: any) => {
  const text = await response.text();
  try {
    const json = JSON.parse(text);
    return json;
  } catch (err) {
    // throw new Error("Did not receive JSON, instead received: " + text);
    return text;
  }
};

export const apiRequestSync = (requestObject: RequestObjectI, data?: RequestDataI) => {
  try {
    const { url, args } = baseRequest(requestObject, data);
    return fetch(url, args).then((res) => res.json());
  } catch (e) {
    console.log('Failed Request', e);
    return e.message;
  }
};
