import { E2eAppSettingsFormPathsI } from 'src/interfaces/application-settings.interfaces';
import { Disclosure, DisclosureAasoI, DisclosureTypeE } from 'src/interfaces/disclosures.interfaces';
import { LendersI } from 'src/interfaces/lenders.interfaces';

export const e2eDiscLogic_doesLenderHaveMultipleDisclosures = (
  selectedDisclosures: E2EChunkedLenderDisclosuresI | Disclosure,
): boolean => {
  if (e2eDiscLogic_isE2EChunkedLenderDisclosuresI(selectedDisclosures)) {
    return selectedDisclosures.disclosures.length > 1;
  } else if (e2eDiscLogic_isDisclosure(selectedDisclosures)) {
    return Array.isArray(selectedDisclosures);
  }
  return false;
};

export interface E2EChunkedLenderDisclosuresI {
  details: LendersI;
  disclosures: Disclosure[];
  lender_id: number;
}
export interface E2eChunkedDisclosuresI {
  lenders: E2EChunkedLenderDisclosuresI[];
  other?: Disclosure[];
  fp?: Disclosure[];
  integration?: Disclosure[];
  processor?: Disclosure[];
}

export const e2eDiscLogic_isE2EChunkedLenderDisclosuresI = (
  disclosure: E2EChunkedLenderDisclosuresI | Disclosure | Disclosure[],
): disclosure is E2EChunkedLenderDisclosuresI => {
  return (disclosure as E2EChunkedLenderDisclosuresI).details !== undefined;
};

export const e2eDiscLogic_isDisclosure = (
  disclosure: E2EChunkedLenderDisclosuresI | Disclosure | Disclosure[],
): disclosure is E2EChunkedLenderDisclosuresI => {
  return (disclosure as Disclosure)._id !== undefined || Array.isArray(disclosure as Disclosure);
};

const sortLendersByIds = (obj: E2eChunkedDisclosuresI, lenderIds: number[]) => {
  return obj.lenders.sort((a, b) => {
    const indexA = lenderIds.indexOf(a.lender_id);
    const indexB = lenderIds.indexOf(b.lender_id);

    // If a lender_id is not in the lenderIds array, it will be placed at the end
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;

    return indexA - indexB;
  });
};

export const e2eDiscLogic_chunkDisclosures = (
  discs: Disclosure[],
  lenders: LendersI[] | undefined,
  e2ePath: E2eAppSettingsFormPathsI | undefined,
): E2eChunkedDisclosuresI => {
  // we want to break up disclosures by disclosure_type and lender_id
  if (!lenders) throw new Error('Lenders are required');
  if (!e2ePath) throw new Error('e2ePath is required');
  const lenderIds = e2ePath.lenders;
  const filteredLenders = lenders?.filter((l) => e2ePath.lenders?.includes(l.lender_id));
  const obj: E2eChunkedDisclosuresI = {
    lenders: [],
  };
  const fpDiscTypes = [
    DisclosureTypeE.fp,
    DisclosureTypeE.fp_merchant,
    DisclosureTypeE.fp_privacy,
    DisclosureTypeE.fp_sms_consent,
  ];
  for (const disc of discs) {
    if (disc.disclosure_type === DisclosureTypeE.lender) {
      const l = filteredLenders.find((l) => l.lender_id == disc.lender_id);
      if (l) {
        const chunk = obj?.lenders?.find((c) => c.details.lender_id === l.lender_id);
        if (chunk) {
          chunk.disclosures.push(disc);
        } else {
          obj.lenders.push({
            details: l,
            disclosures: [disc],
            lender_id: l.lender_id,
          });
        }
      }
    }
    if (fpDiscTypes.includes(disc.disclosure_type)) {
      if (!obj.fp) {
        obj.fp = [];
      }
      obj.fp.push(disc);
    }
    if (disc.disclosure_type === DisclosureTypeE.integration) {
      if (!obj.integration) {
        obj.integration = [];
      }
      obj.integration.push(disc);
    }
    if (disc.disclosure_type === DisclosureTypeE.processor) {
      if (!obj.processor) {
        obj.processor = [];
      }
      obj.processor.push(disc);
    }
    if (disc.disclosure_type === DisclosureTypeE.other) {
      if (!obj.other) {
        obj.other = [];
      }
      obj.other.push(disc);
    }
  }
  const sortedLenders = sortLendersByIds(obj, lenderIds);
  const result = sortedLenders.map((lender) => ({
    details: lender.details,
    lender_id: lender.lender_id,
    disclosures: lender.disclosures,
  }));
  const newObj = {
    fp: obj.fp,
    lenders: result,
  };
  return newObj;
};

export const e2eDiscLogic_hasAcceptedAllDisclosures = (
  chunkedDisclosures?: E2eChunkedDisclosuresI,
  aasoAcceptedDisclosures?: DisclosureAasoI[],
): boolean => {
  if (
    chunkedDisclosures &&
    aasoAcceptedDisclosures &&
    chunkedDisclosures.lenders.length > 0 &&
    aasoAcceptedDisclosures.filter((disclosure) => !disclosure.opt_in_marketing).length > 0
  ) {
    // right now we're only handling lender disclosures
    const lenders = chunkedDisclosures.lenders;
    for (const lender of lenders) {
      const lenderDiscs = lender.disclosures;
      for (const disc of lenderDiscs) {
        const accepted = aasoAcceptedDisclosures.find((a) => a._id === disc._id);
        if (!accepted) {
          return false;
        }
      }
    }
    return true;
  }
  return false;
};
export const e2eDiscLogic_selectDisclosureToRender = (
  chunkedDisclosures: E2eChunkedDisclosuresI,
  aasoAcceptedDisclosures: DisclosureAasoI[],
): Disclosure | E2EChunkedLenderDisclosuresI | undefined => {
  // can return a disclosure or a chunked lender
  // R:TODO E2E P0 - add other disclosure types (minus FPs)
  const lenders = chunkedDisclosures.lenders;
  // const sorted = lenders.sort((a, b) => a.details.name.localeCompare(b.details.name));
  for (const lender of lenders) {
    const lenderDiscs = lender.disclosures;
    for (const disc of lenderDiscs) {
      const accepted = aasoAcceptedDisclosures.find((a) => a._id === disc._id);
      if (!accepted) {
        return lender;
      }
    }
  }
};
