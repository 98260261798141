import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { E2eAppSettingsFormPathsI } from 'src/interfaces/application-settings.interfaces';

import ThemedCard from '../../components/ThemedCard';

interface ApplicationCardProps {
  title: string;
  buttonText: string;
  onDetailsClick: () => void;
  notice?: string;
  handleSelectPathType: () => void;
  path: E2eAppSettingsFormPathsI;
}

const ApplicationCard: React.FC<ApplicationCardProps> = (props: ApplicationCardProps) => {
  const { title, buttonText, onDetailsClick, notice, handleSelectPathType } = props;
  return (
    <ThemedCard
      backgroundColor="white"
      sx={{
        paddingTop: { xs: '30px', md: '50px' },
        paddingBottom: { xs: '30px', md: '50px' },
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography component="h2" variant="h1" sx={{ paddingBottom: '20px' }}>
        {title}
      </Typography>
      <Typography
        component="p"
        variant="body1"
        sx={{ paddingBottom: '20px', textDecoration: 'underline', cursor: 'pointer' }}
        onClick={onDetailsClick}
      >
        Payment Option Details
      </Typography>
      {notice && notice.length > 0 && (
        <Typography component="p" variant="body1" sx={{ marginBottom: '23px' }}>
          {notice}
        </Typography>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 'auto' }}>
        <Button variant="contained" color="warning" size="large" onClick={handleSelectPathType}>
          {buttonText}
        </Button>
      </Box>
    </ThemedCard>
  );
};

export default ApplicationCard;
