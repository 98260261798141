import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import {
  e2eConfigLogic_buildProviderResponseLogicObject,
  ProviderOfferLogicObjectI,
  ProviderOffersI,
} from 'src/e2e-redesign/business-logic/e2e-configuration-object-logic';
import {
  ApprovalUrlParamsI,
  handleE2eApplicantApprovedNewOfferRouting,
} from 'src/e2e-redesign/business-logic/e2e-routing-logic';
import { e2eSubLogic_handleAcceptNewOffer } from 'src/e2e-redesign/business-logic/e2e-submission-logic';
import SingleLogo from 'src/e2e-redesign/components/SingleLogo';
import ThemedCard from 'src/e2e-redesign/components/ThemedCard';
import ChargeApprovalCard from 'src/e2e-redesign/views/ApproveNewOffer/charge-approval-card';
import StandardApprovalCard from 'src/e2e-redesign/views/ApproveNewOffer/standard-approval-card';
import { AasoOffersToAcceptI } from 'src/interfaces/aaso.interfaces';
import { Disclosure } from 'src/interfaces/disclosures.interfaces';
import { LendersI } from 'src/interfaces/lenders.interfaces';
import {
  ApplicationSubmissionStateDto,
  AppSubLenderResponseE,
  FlowEndPointsE,
  LenderOffersDetailedI,
  LenderOffersI,
} from 'src/interfaces/submissions.interfaces';

export enum CustomerNotificationChannelE {
  'sms' = 'sms',
  'email' = 'email',
}
export interface AfterApproveActionsI {
  lender_id: number;
  endpoints_order: FlowEndPointsE | FlowEndPointsE[];
}

export interface AfterApproveI {
  actions: AfterApproveActionsI[];
}

export interface AasoOfferAcceptDetailsI {
  time: Date;
  time_unix: number;
  ip: string[];
}

export interface CustomerNotificationDetailsI {
  // channel: CustomerNotificationChannelE[];
  message: string;
  base_msg?: string; // no link
  enabled: boolean;
  sent_time?: string; // unix time
  failed_message?: string;
  fp_unique_id?: string;
  url?: string;
  after_approve?: AfterApproveI;
  ui_config?: {
    columns?: LenderOffersDetailedI[];
    disclosures?: string;
  };
}

export interface ApprovalProviderOffersI {
  sub_details: ApplicationSubmissionStateDto;
  offer_details: AasoOffersToAcceptI;
  lender: LendersI;
  lender_disclosures?: Disclosure[];
  is_offer: boolean;
}

interface ResponseCardProps {
  // response: ProviderResponse;
  card_details: ApprovalProviderOffersI;
}

export default function ApprovalSubmissionCard(props: ResponseCardProps) {
  const { card_details } = props;
  const params = useParams<ApprovalUrlParamsI>();
  const { fp_unique_id, aaso_id, form_id, store_name, e2e_form_id, store_uuid } = params;
  const navigate = useNavigate();
  const offerDetails: AasoOffersToAcceptI = card_details.offer_details;
  const submissionDetails: ApplicationSubmissionStateDto = card_details.sub_details;
  const [offerLogicObj, setOfferLogicObj] = useState<ProviderOfferLogicObjectI>();
  const l: LendersI = card_details.lender;
  const lenderDisclosures = card_details.lender_disclosures;
  if (!e2e_form_id || !store_uuid || !aaso_id || !store_name || !fp_unique_id || !form_id)
    throw new Error('No key params');

  useEffect(() => {
    if (card_details) {
      const obj = e2eConfigLogic_buildProviderResponseLogicObject(card_details);
      if (obj) {
        setOfferLogicObj(obj);
      }
    }
  }, [props.card_details]);

  // R:TODO E2E P0:R - add this back - Brad, where is this supposed to go? this goes for everyone so no conditions

  const getLenderLogo = () => {
    if (l.logo_url) {
      // R:TODO E2E P3 - anywhere we show lender logos, we need to first pull from their disclosure, see if it exists and works, if not pull from lender object
      // Go based on the lender object logo url
      return {
        src: l.logo_url,
        alt: l.name,
      };
    } else {
      // if no logo exists in lender object, use disclosures
      if (lenderDisclosures && lenderDisclosures?.length > 0) {
        const disc = lenderDisclosures.find((ld) => ld.logo.length > 0);
        if (disc) {
          return {
            src: disc.logo,
            alt: l.name,
          };
        }
      }
    }
    return false;
  };
  if (!offerLogicObj) return null;

  const handleAcceptAndProceed = async () => {
    try {
      const res = await e2eSubLogic_handleAcceptNewOffer(offerDetails, l, params);
      if (res) {
        handleE2eApplicantApprovedNewOfferRouting(navigate, params);
      } else {
        toast.error('Your token has expired');
        setTimeout(() => {
          location.reload();
        }, 5000);
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const lenderLogoDetails = getLenderLogo();

  if (offerDetails.logic_details) {
    return (
      <ChargeApprovalCard
        lenderLogoDetails={lenderLogoDetails}
        submissionDetails={submissionDetails}
        offerDetails={offerDetails}
        offerLogicObj={offerLogicObj}
        handleAcceptAndProceed={handleAcceptAndProceed}
      />
    );
  } else {
    return (
      <StandardApprovalCard
        lenderLogoDetails={lenderLogoDetails}
        submissionDetails={submissionDetails}
        offerDetails={offerDetails}
        offerLogicObj={offerLogicObj}
        handleAcceptAndProceed={handleAcceptAndProceed}
      />
    );
  }
  // return (
  //   <ThemedCard sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
  //     <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: '52px' }}>
  //       {lenderLogoDetails && <SingleLogo image={lenderLogoDetails} />}
  //     </Box>
  //     <dl style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '5px', marginBottom: '20px' }}>
  //       {renderDefinitionItem('Response', submissionDetails.lender_response)}
  //       {renderDefinitionItem('Approved Amount', offerDetails.approved_amount)}
  //       {renderDefinitionItem('Term', offerDetails.term)}
  //       {/* {renderDefinitionItem('Deferred Interest', response.deferredInterest)} */}
  //       {/* {renderDefinitionItem('Minimum Spend', response.minimumSpend)} */}
  //     </dl>
  //     {/* TODO: Add response.message conditionally here */}
  //     {offerDetails.alternate_message && (
  //       <Typography variant="body1" style={{ margin: '20px' }} paragraph>
  //         {offerDetails.alternate_message}
  //       </Typography>
  //     )}
  //     {offerLogicObj.show_approve_and_proceed && (
  //       <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 'auto' }}>
  //         <Button variant="contained" color="success" fullWidth onClick={handleAcceptAndProceed}>
  //           Proceed
  //         </Button>
  //       </Box>
  //     )}
  //   </ThemedCard>
  // );
}
