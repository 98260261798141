import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ProviderOfferLogicObjectI } from 'src/e2e-redesign/business-logic/e2e-configuration-object-logic';
import SingleLogo from 'src/e2e-redesign/components/SingleLogo';
import ThemedCard from 'src/e2e-redesign/components/ThemedCard';
import { AasoOffersToAcceptI } from 'src/interfaces/aaso.interfaces';
import { ApplicationSubmissionStateDto, LenderOffersDetailedI } from 'src/interfaces/submissions.interfaces';

interface StandardApprovalCardProps {
  lenderLogoDetails: any;
  submissionDetails: ApplicationSubmissionStateDto;
  offerDetails: AasoOffersToAcceptI;
  handleAcceptAndProceed: () => void;
  offerLogicObj: ProviderOfferLogicObjectI;
}

export default function ChargeApprovalCard(props: StandardApprovalCardProps) {
  const theme = useTheme();
  const { offerLogicObj, lenderLogoDetails, submissionDetails, offerDetails, handleAcceptAndProceed } = props;
  const offer = offerDetails.offer;
  const uiConfig = offerDetails?.logic_details?.ui_config;
  const afterApprove = offerDetails?.logic_details?.after_approve;
  const columns: LenderOffersDetailedI[] | undefined = uiConfig?.columns;
  const disc = uiConfig?.disclosures;
  if (!columns) return null;

  const renderDefinitionItem = (term: string, definition: string | number | undefined) => {
    if (definition === undefined || definition === '') return null;
    return (
      <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
        <Typography
          component="dt"
          variant="body1"
          style={{
            color: theme.palette.text.primary,
            marginRight: '8px',
          }}
        >
          {term}
        </Typography>
        <Typography
          component="dd"
          variant="body1"
          style={{
            color: theme.palette.primary.main,
            margin: 0,
          }}
        >
          {definition}
        </Typography>
      </Box>
    );
  };

  return (
    <ThemedCard sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: '52px' }}>
        {lenderLogoDetails && <SingleLogo image={lenderLogoDetails} />}
      </Box>
      <dl
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto auto',
          gap: '16px',
          marginBottom: '20px',
        }}
      >
        {columns.map((c) => {
          if (c.show) {
            return renderDefinitionItem(c.label, c.value);
          }
          return null;
        })}
      </dl>
      {/* TODO: Add response.message conditionally here */}
      {disc && (
        <Typography variant="body1" style={{ margin: '20px' }} paragraph>
          {disc}
        </Typography>
      )}
      {offerLogicObj.show_approve_and_proceed && (
        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 'auto' }}>
          <Button variant="contained" color="success" fullWidth onClick={handleAcceptAndProceed}>
            Proceed
          </Button>
        </Box>
      )}
    </ThemedCard>
  );
}
