import React from 'react';
import { Box, Grid } from '@mui/material';
import ApplicationModal from 'src/components/modals/continue-application/continue-application-modal';
import { Seo } from 'src/components/seo';
import {
  E2eLogicObjectsWithParams,
  e2eLogicUtils_getBaseRoutingLogicObjects,
} from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import { welcomeLogic_init, welcomLogic_funcAndV } from 'src/e2e-redesign/views/Welcome/logic/welcom-logic';

import LogoGrid from '../../components/LogoGrid';
import PageIntro from '../../components/PageIntro';
import ThemedContainer from '../../components/ThemedContainer';
import WelcomeCard from './WelcomeCard';

export default function Welcome() {
  const routingPackage: E2eLogicObjectsWithParams = e2eLogicUtils_getBaseRoutingLogicObjects();
  welcomeLogic_init(routingPackage);
  const fnv = welcomLogic_funcAndV(routingPackage);
  const { handleLogin, handleStartNew, handleContinue, handleCreateAccount } = fnv.fn;
  const { continueRoute, openContinueApp, continueAASO, disclosureLogos } = fnv.v;

  // HOWTO - example of using mutations with tanstack and zustand
  // const [enabled, setEnabled] = useState<boolean>(false);
  // const queryClient = useQueryClient();
  // const mutateAppSettings = exampleOfChangingApplicationSettings({
  //   reqData: { params: { store_uuid: storeUuid }, body: { some: 'data' } },
  //   queryKey: storeUuid,
  //   setState: e2eStore.setLogicObjects,
  //   queryClient,
  // });
  // const handleButtonClick = (data: any) => {
  //   mutateAppSettings.mutate(data);
  // };

  return (
    <>
      <Seo title="Welcome" />
      {disclosureLogos && disclosureLogos.length > 0 && (
        <ThemedContainer>
          <Grid container spacing={2}>
            <Grid sx={{ display: { xs: 'none', sm: 'block' } }} item xs={12} md={7}>
              <PageIntro
                title="Welcome!"
                paragraph="Professional software developed to securely connect you with numerous third-party payment options."
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <WelcomeCard
                title="New to FormPiper?"
                buttonText="Create account"
                buttonColor="warning"
                onButtonClick={handleCreateAccount}
              />
            </Grid>
            {/* HOWTO - use tanstack and zustand togther */}
            {/* <Button onClick={() => handleButtonClick({ some: 'data' })}>Click me to test mutation</Button> */}
            <Grid item xs={12} sm={6}>
              <WelcomeCard
                title="Been here before?"
                buttonText="Log in"
                buttonColor="primary"
                onButtonClick={handleLogin}
              />
            </Grid>
          </Grid>
          <Box sx={{ marginTop: '50px' }}>
            <LogoGrid
              logos={disclosureLogos}
              store_image_url={routingPackage?.e2eStore?.app_configurations?.logo_url}
            />
          </Box>
          <ApplicationModal
            open={openContinueApp}
            handleContinue={handleContinue}
            handleStartNew={handleStartNew}
            continueAASO={continueAASO}
            continueRoute={continueRoute}
          />
        </ThemedContainer>
      )}
    </>
  );
}
