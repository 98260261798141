import { useCallback } from 'react';
import { Box, List, ListItem, ListItemButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import toast from 'react-hot-toast';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from 'src/hooks/use-auth';
import { useRouter } from 'src/hooks/use-router';
import { Issuer } from 'src/types/auth';
import { debounce } from 'lodash';
interface DashboardNavigationProps {
  onNavigate: () => void;
}

interface NavigationItem {
  text: string;
  to: string;
}

export default function DashboardNavigation({ onNavigate }: DashboardNavigationProps) {
  const theme = useTheme();
  const router = useRouter();
  const auth = useAuth();
  const { user } = auth;

  const handleLogout = useCallback(async (): Promise<void> => {
    try {
      switch (auth.issuer) {
        case Issuer.JWT: {
          await auth.signOut();
          break;
        }

        default: {
          console.warn('Using an unknown Auth Issuer, did not log out');
        }
      }
      window.location.reload();
      // router.push('/');
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong!');
    }
  }, [auth, router]);

  const handleClick = useCallback(() => {
    try {
      onNavigate();
    } catch (error) {
      console.error('Error during navigation:', error);
    }
  }, [onNavigate]);

  const debouncedHandleClick = useCallback(debounce(handleClick, 300), [handleClick]);

  const navigationItems: NavigationItem[] = [
    { text: 'Dashboard', to: '/dashboard' },
    { text: 'Settings', to: '/dashboard/account' },
    // { text: 'Incomplete Offers', to: '/dashboard/applications' },
    // { text: 'Accepted Offers', to: '/dashboard/offers-accepted' },
    // { text: 'Logout', to: '/logout' },
  ];

  return (
    <Box component="nav">
      <List>
        {navigationItems.map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton
              component={RouterLink}
              to={item.to}
              onClick={debouncedHandleClick}
              sx={{
                color: theme.palette.primary.main,
                justifyContent: 'flex-end',
                py: 2,
                '&:hover': {
                  backgroundColor: theme.palette.primary.light,
                  color: theme.palette.primary.contrastText,
                },
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontSize: '1.5rem',
                  fontWeight: 600,
                  textAlign: 'right',
                }}
              >
                {item.text}
              </Typography>
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem key={'logout'} disablePadding>
          <ListItemButton
            onClick={handleLogout}
            sx={{
              color: theme.palette.primary.main,
              justifyContent: 'flex-end',
              py: 2,
              '&:hover': {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
              },
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: '1.5rem',
                fontWeight: 600,
                textAlign: 'right',
              }}
            >
              Logout
            </Typography>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
}
