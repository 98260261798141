import React, { useMemo } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import SingleLogo from 'src/e2e-redesign/components/SingleLogo';
import ThemedContainer from 'src/e2e-redesign/components/ThemedContainer';
import { E2eClientErrorDisclosures } from 'src/errors/error.interfaces';
import { Disclosure } from 'src/interfaces/disclosures.interfaces';
import * as Yup from 'yup';

// R:TODO E2E P1 - Brad - can you get these styles to work?
// import styles from './sign-missing-disclosures.d-modal';

export interface SingleProviderDisclosuresProps {
  disclosure: Disclosure;
  onContinue: (data: any) => void;
  errorData: E2eClientErrorDisclosures;
}
export default function MissingSingleDisclosure(props: SingleProviderDisclosuresProps) {
  const { disclosure, onContinue, errorData } = props;
  const initialValues = useMemo(
    () => ({
      agreeTerms: false,
      optInMarketing: false,
    }),
    [disclosure],
  );

  const theme = useTheme();
  if (!disclosure) return <></>;
  const logo = {
    src: disclosure?.logo || '',
    alt: disclosure?.name || '',
  };

  const handleOnContinue = (data: any) => {
    onContinue({
      ...data,
      _id: disclosure._id,
      name: disclosure.name,
      aaso_id: errorData?.aaso_id,
      form_id: errorData?.form_id,
    });
  };

  const validationSchema = Yup.object().shape({
    agreeTerms: Yup.boolean().oneOf([true], 'You must agree to the terms.'),
    // optInMarketing: disclosure?.opt_in_marketing
    //   ? Yup.boolean().oneOf([true], 'You must opt in to marketing communications.')
    //   : Yup.boolean(),
  });

  return (
    <ThemedContainer>
      <Typography sx={{ color: '#243F8F', marginBottom: '20px' }} variant="h3" component="h1" gutterBottom>
      Disclosure Certification
      </Typography>
      <Box mb={2} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        <SingleLogo image={logo} />
      </Box>
      <Typography paragraph variant="body1">
        By clicking “I AGREE” below you acknowledge the following:
      </Typography>
      <Box
        sx={{
          border: '1px solid #E0E8F5',
          borderRadius: '15px',
          padding: '10px',
          height: { xs: '450px', md: '300px' },
          overflowY: 'scroll',
          marginBottom: '20px',
          // TODO: Add themed scrollbar to a global css file if possible
          '&::-webkit-scrollbar': {
            width: '12px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#ffffff',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#E0E8F5',
            borderRadius: '15px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#B0C4DE',
          },
        }}
      >
        <div
          // className={styles.dialogContent}
          dangerouslySetInnerHTML={{ __html: disclosure?.document ? disclosure.document : '' }}
        />
      </Box>
      <Formik
        key={disclosure._id} // Force remount when disclosure changes
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnChange
        onSubmit={(values, { setSubmitting }) => {
          handleOnContinue(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <Box mb={2}>
                <Field
                  component={CheckboxWithLabel}
                  name="agreeTerms"
                  id="agreeTerms"
                  type="checkbox"
                  Label={{ label: 'I agree to the above terms.' }}
                  // checked={values.agreeTerms}
                  // value={values.agreeTerms}
                />
                <ErrorMessage
                  name="agreeTerms"
                  component="div"
                  render={(msg) => <div style={{ color: theme.palette.error.main }}>{msg}</div>}
                />
              </Box>
              {disclosure?.opt_in_marketing && (
                <Box mb={6}>
                  <Field
                    component={CheckboxWithLabel}
                    name="optInMarketing"
                    type="checkbox"
                    Label={{ label: 'I opt in to marketing communications.' }}
                    // value={values.optInMarketing}
                    // checked={values.optInMarketing}
                  />
                  <ErrorMessage
                    name="optInMarketing"
                    component="div"
                    render={(msg) => <div style={{ color: theme.palette.error.main }}>{msg}</div>}
                  />
                </Box>
              )}
              {/* TODO: Make stack on mobile */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  justifyContent: { md: 'flex-start' },
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <Button variant="contained" color="warning" size="large" type="submit" disabled={isSubmitting}>
                  Continue
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </ThemedContainer>
  );
}
