import React from 'react';
import { RedirectedOffersI } from 'src/e2e-redesign/business-logic/e2e-business-logic';
import ThemedDialog from 'src/e2e-redesign/components/ThemedDialog/ThemedDialog';

interface SynchronyRedirectModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  paragraph: string;
  imageUrl: string;
  submittedTabs: RedirectedOffersI[];
}

const RedirectModal: React.FC<SynchronyRedirectModalProps> = ({
  open,
  onClose,
  title,
  paragraph,
  imageUrl,
  submittedTabs,
}) => {
  if (submittedTabs && submittedTabs.length > 0) {
    const urlObj = submittedTabs[0];
    const content = `
    <div style="text-align: center;">
      <h2>${title}</h2>
      <p>${paragraph}</p>
      <p style="font-size: 15px">If you have not been automatically redirect, please <a href="${urlObj.redirect_url}" target="_blank"> click here. </a></p>
      <img src="${imageUrl}" alt="Redirecting" style="max-width: 100%; margin-top: 20px;" />
    </div>
  `;

    return <ThemedDialog open={open} onClose={onClose} content={content} />;
  }

  const without = `
    <div style="text-align: center;">
      <h2>${title}</h2>
      <p>${paragraph}</p>
      <img src="${imageUrl}" alt="Redirecting" style="max-width: 100%; margin-top: 20px;" />
    </div>
  `;
  return <ThemedDialog open={open} onClose={onClose} content={without} />;
};

export default RedirectModal;
