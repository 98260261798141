import React from 'react';
import { Grid, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { CheckCircle, Cancel } from '@mui/icons-material';

interface PasswordRequirementsProps {
  requirements: string[];
  password: string;
}

const PasswordRequirements: React.FC<PasswordRequirementsProps> = ({ requirements, password }) => {
  const checkRequirement = (requirement: string): boolean => {
    switch (requirement) {
      case 'At least 8 characters':
        return password.length >= 8;
      case 'Contains at least one uppercase letter':
        return /[A-Z]/.test(password);
      case 'Contains at least one lowercase letter':
        return /[a-z]/.test(password);
      case 'Includes at least one number':
        return /\d/.test(password);
      case 'Includes at least one special character (@$!%*?&#)':
        return /[@$!%*?&#]/.test(password);
      default:
        return false;
    }
  };

  return (
    <div>
      <Typography gutterBottom>
        Password Requirements:
      </Typography>
      <Grid container spacing={1}>
        {requirements.map((req, index) => (
         <Grid item xs={6} key={index} container alignItems="center">
            <Grid item>
            {checkRequirement(req) ? <CheckCircle color="success" /> : <Cancel color="error" />}
            </Grid>
            <Grid item>
            <Typography style={{ marginLeft: 8 }}> 
            {req}
            </Typography>
            </Grid>
         </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default PasswordRequirements;