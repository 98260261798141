import { ApiMethodTypes, RequestObjectI } from 'src/api/request-objects';

// BRYER
export const API_E2E_GET_DISCLOSURES: RequestObjectI = {
  url: 'disclosures/:store_uuid/:e2e_form_id/get-disclosures',
  method: ApiMethodTypes.GET,
};

export const API_E2E_GET_LENDER_LOGOS: RequestObjectI = {
  url: 'disclosures/:store_uuid/:e2e_form_id/get-lender-logos',
  method: ApiMethodTypes.GET,
};

export const API_E2E_GET_MISSING_DISCLOSURES: RequestObjectI = {
  url: 'disclosures/get-missing-disclosures',
  method: ApiMethodTypes.POST,
};

export const API_E2E_POST_MISSING_DISCLOSURES: RequestObjectI = {
  url: 'disclosures/save-missing-disclosures',
  method: ApiMethodTypes.POST,
};

export const API_E2E_GET_FP_DISCLOSURES: RequestObjectI = {
  url: 'disclosures/get-fp-disclosures',
  method: ApiMethodTypes.GET,
};

// BRYER
export const API_E2E_POST_DISCLOSURES: RequestObjectI = {
  url: 'disclosures/:store_uuid/:e2e_form_id/:aaso_id/save-disclosures',
  method: ApiMethodTypes.POST,
};

/**
 * This gets e2eStoreSettingsAcl, lenderObject, e2eApplicationSettings
 */
export const API_E2E_GET_APPLICATION_SETTINGS: RequestObjectI = {
  url: 'e2e/:store_uuid/:e2e_form_id/get-business-logic-objects',
  method: ApiMethodTypes.GET,
};

export const API_E2E_GET_INIT_APP_APPROVAL: RequestObjectI = {
  url: 'e2e/:aaso_id/:form_id/:fp_unique_id/init-verification',
  method: ApiMethodTypes.GET,
};

export const API_E2E_GET_INIT_APP_APPROVAL_OFFERS: RequestObjectI = {
  url: 'e2e/:aaso_id/:form_id/:fp_unique_id/get-offers',
  method: ApiMethodTypes.GET,
};

export const API_E2E_GET_INIT_APP_APPROVAL_VERIFY: RequestObjectI = {
  url: 'e2e/:aaso_id/:form_id/:fp_unique_id/verify',
  method: ApiMethodTypes.POST,
};

export const API_E2E_GET_LIMITED_APPLICATION_SETTINGS: RequestObjectI = {
  url: 'e2e/:store_uuid/:e2e_form_id/get-l-business-logic-objects',
  method: ApiMethodTypes.GET,
};

// R:TODO E2E P0 - Remove this as it's just used for an example
export const API_E2E_PATCH_APPLICATION_SETTINGS: RequestObjectI = {
  url: 'application-settings/:store_uuid/:e2e_form_id/patch-app-settings',
  method: ApiMethodTypes.PATCH,
};

// BRYER
export const API_E2E_GET_LENDER_DETAILS: RequestObjectI = {
  url: 'lenders/:store_uuid/lender-details',
  method: ApiMethodTypes.GET,
};

export const API_E2E_POST_AASO_DETAILS: RequestObjectI = {
  url: 'aaso/:applicant_id/:store_uuid/:e2e_form_id/create',
  method: ApiMethodTypes.POST,
};

export const API_E2E_GET_AASO_BY_ID: RequestObjectI = {
  url: 'aaso/:aaso_id/get-aaso',
  method: ApiMethodTypes.GET,
};

export const API_E2E_PATCH_AASO: RequestObjectI = {
  url: 'aaso/:applicant_id/:aaso_id/:store_uuid/:e2e_form_id/save-aaso',
  method: ApiMethodTypes.PATCH,
};

export const API_E2E_GET_APPLICANT_FORM: RequestObjectI = {
  url: 'forms/:aaso_id/get-forms',
  method: ApiMethodTypes.GET,
};

export const API_E2E_POST_VERIFY_CHECK_CREATE_ACCOUNT: RequestObjectI = {
  url: 'auth/check-and-create-account',
  method: ApiMethodTypes.POST,
};

// BRYER
export const API_E2E_POST_SEND_VERIFICATION: RequestObjectI = {
  url: 'verify/send-verification',
  method: ApiMethodTypes.POST,
};

export const API_GET_APPLICANT_EXISTS: RequestObjectI = {
  url: 'applicants/exists/:email',
  method: ApiMethodTypes.GET,
};

export const API_E2E_POST_VALIDATE_APPLICANT: RequestObjectI = {
  url: 'applicants/validate',
  method: ApiMethodTypes.POST,
};

// BRYER
export const API_E2E_GET_FORM_SUBMISSIONS: RequestObjectI = {
  url: 'e2e/:aaso_id/:e2e_form_id/submissions',
  method: ApiMethodTypes.GET,
};

// this is what submits the apps to the lenders
export const API_E2E_GET_KICKOFF: RequestObjectI = {
  url: 'e2e/:aaso_id/:e2e_form_id/:store_uuid/kickoff',
  method: ApiMethodTypes.GET,
};

export const API_E2E_GET_PROVIDER_STATUS: RequestObjectI = {
  url: 'e2e/:aaso_id/:e2e_form_id/:store_uuid/:lender_id/get-provider-status',
  method: ApiMethodTypes.GET,
};

export const API_E2E_GET_CONTINUE_WATERFALL_PIPING: RequestObjectI = {
  url: 'e2e/:aaso_id/:e2e_form_id/:store_uuid/continue-waterfall-piping',
  method: ApiMethodTypes.GET,
};

export const API_E2E_POST_FORM: RequestObjectI = {
  url: 'forms/:store_uuid/:aaso_id/:e2e_form_id/submit-form',
  method: ApiMethodTypes.POST,
};

export const API_E2E_POST_FORM_ABANDONED: RequestObjectI = {
  url: 'forms/:store_uuid/:aaso_id/:e2e_form_id/save-abandoned-form',
  method: ApiMethodTypes.POST,
};

export const API_E2E_GET_AASO_BY_APPLICANT: RequestObjectI = {
  url: 'aaso/applicant/:store_uuid/:e2e_form_id/get-aaso-by-applicant-id',
  method: ApiMethodTypes.GET,
};

export const API_E2E_PATCH_AASO_OFFER_SELECTION: RequestObjectI = {
  url: 'aaso/applicant/:store_uuid/:aaso_id/:e2e_form_id/offer-selection',
  method: ApiMethodTypes.PATCH,
};

export const API_E2E_PATCH_NEW_OFFER_SELECTED: RequestObjectI = {
  url: 'aaso/applicant/:fp_unique_id/:aaso_id/:form_id/new-offer-selection',
  method: ApiMethodTypes.PATCH,
};
